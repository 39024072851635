/** @format */
import * as React from "react";
import { TbEdit } from "react-icons/tb";
import { MdContentCopy } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosEye } from "react-icons/io";
import { IoMdSearch } from "react-icons/io";

import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";

import { URI } from "../URL";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
// import { port } from "../../Data";
function AllProucts() {
  //   const { data, deleteProduct } = useFetchData();
  const [keyWord,setKeyWord]=useState('')
  const [activeReviewType, setActiveReviewType] = useState("All");
  const RevieTypes = [
    { title: "All" },
    { title: "text" },
    { title: "picture" },
    { title: "video" },
    { title: "rating" },
    { title: "no review" },
  ];
  const marketplaceOptions = ['All',"US", "UK", "DE", "IT", "ES", "CA", "AU"];
  const [activeMarketplace, setActiveMarketplace] = useState(marketplaceOptions[0]);

  const { loading, products, getProducts ,setProducts} = useContext(AuthContext);
  const navigate = useNavigate();

  const callApiForMarketplace = async (rating="",marketplace="",keyWord) => {
    setActiveReviewType(rating);
    setActiveMarketplace(marketplace)
const token = await localStorage.getItem('token')
    try {
      // Construct the API URL with the active marketplace and reviewType as 'rating'
      const response = await axios.get(
        `${URI}/api/products/filter?marketPlace=${marketplace}&reviewType=${rating}&keyword=${keyWord}`,{headers:{Authorization:`${token}`}}
      );
      setProducts(response.data?.products); // Store the products in the state
    } catch (error) {
      console.error( error);
    }
  };
  // Set initial active marketplace to the first option

  // Function to handle marketplace selection
  const handleMarketplaceClick = (marketplace) => {
    setActiveMarketplace(marketplace);
    
    // Call your API when the marketplace is changed
    callApiForMarketplace(marketplace);
  };

  // API call function

  const deleteProduct = async (id) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.delete(`${URI}/api/products/${id}/delete`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (response.status === 200) {
        await getProducts();
      }
      const data = response.data;

    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    getProducts();
  }, []);
  return (
    <div className="px-6">
      <div className="flex items-center justify-start gap-3 my-3">
        <h1 className="text-lg font-semibold capitalize my-2">products</h1>
        <div className="border flex items-stretch justify-center ps-2 overflow-hidden rounded-md h-10">
          <input
            type="search"
            name=""
            id=""
            placeholder="search here"
            onChange={(e)=>setKeyWord(e.target.value)}
            className="  placeholder:capitalize placeholder:text-slate-500 placeholder:font-medium  outline-none "
          />
          <button className="bg-black p-3 text-white  h-full  text-base" onClick={() => callApiForMarketplace(activeReviewType,activeMarketplace,keyWord)}>
            <IoMdSearch />
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between my-6">
      <div className="flex items-center justify-center gap-3">
        {RevieTypes.map((review) => (
          <p
            key={review.title}
            onClick={() => callApiForMarketplace(review.title,activeMarketplace,keyWord)}
            className={`px-2 py-1 cursor-pointer transition-all duration-300 border rounded-lg text-sm ${
              activeReviewType === review.title
                ? "bg-blue-600 text-white border-blue-600"
                : "hover:bg-blue-600 hover:text-white border-blue-600"
            }`}
          >
            {review.title}
          </p>
        ))}
      </div>
        <div className="flex items-center justify-center gap-3">
      {marketplaceOptions.map((place) => (
        <p
          key={place}
          className={`px-2 py-1 cursor-pointer transition-all duration-300 border rounded-lg text-sm ${
            place === activeMarketplace
              ? "bg-blue-600 text-white border-blue-600"
              : "hover:bg-blue-600 hover:text-white border-blue-600"
          }`}
          onClick={() => callApiForMarketplace(activeReviewType,place,keyWord)}
        >
          {place}
        </p>
      ))}
    </div>
      </div>
      <div className="flex flex-col items-start justify-start">
        <div className="flex min-w-max gap-4 bg-slate-100 w-full py-2 px-4 items-center  justify-between">
          <p className="text-center w-20 capitalize font-semibold ">#</p>
          <p className="text-center w-20 capitalize font-semibold ">title</p>
          <p className="text-start w-40 capitalize font-semibold ">
            description
          </p>

          <p className="text-center w-20 capitalize font-semibold ">price</p>
          <p className="text-center w-20 capitalize font-semibold ">image</p>
          <p className="text-center w-20 capitalize font-semibold ">status</p>
          <p className="text-center w-20 capitalize font-semibold ">actions</p>
        </div>
        {loading ? (
          <div className="font-semibold text-2xl text-center w-full mt-10 capitalize">
            laoding...
          </div>
        ) : products && products.length > 0 ? (
          products.map((item, i) => (
            <div className="flex  gap-4 bg-white w-full py-2 px-4 items-center  justify-between" key={i}>
              <p className="text-center w-20 capitalize font-semibold ">
                {i + 1}
              </p>
              <p className=" w-20 capitalize font-medium text-base text-start ">
                {item.name}
              </p>
              <p className="text-start w-40 text-sm  ">
                {item.description}
                description
              </p>

              <p className="text-center w-20 capitalize font-semibold ">
                {item.price}
              </p>

              <p className="text-start w-20 capitalize font-semibold  flex items-center gap-2">
                <img
                  src={
                    item?.image||"https://247salesmanager.com/Store/edffcf09-141c-407b-93fa-a9969a8c041e.jpg"
                  }
                  className="h-10 w-6 object-cover"
                  alt=""
                />
              </p>

              <p className="text-center w-20 capitalize font-semibold ">
                {item.status}
              </p>
              <p className="text-center w-20 capitalize font-semibold flex flex-col items-center justify-center  gap-2">
                <button
                  className="text-sm h-6 grid place-items-center w-6 rounded-full bg-slate-200"
                  onClick={() => navigate(`/admin/update-product/${item._id}`)}
                >
                  <TbEdit />
                </button>

                <button
                  className="text-sm h-6 grid place-items-center w-6 rounded-full bg-red-100 text-red-500"
                  onClick={() => deleteProduct(item._id)}
                >
                  <RiDeleteBin6Line />
                </button>
              </p>
            </div>
          ))
        ) : (
          <div className="font-bold text-3xl text-center w-full mt-10 capitalize">
            <p>no products found</p>
            <button
              className="w-max  bg-black disabled:opacity-50 text-lg px-4 capitalize  disabled:cursor-wait text-white py-2 rounded-md uppercase my-2"
              onClick={() => {
                navigate("/admin/add-product");
              }}
            >
              add product
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AllProucts;
