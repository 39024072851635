import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { URI } from '../URL';

function SocialShareButton() {
    const { user } = useContext(AuthContext);

    // Create the referral link

    const referralLink = `https://sepehrrealtorsproject.fivezonetech.com/register/${user?.id}`; // Replace with your actual referral link format

    const CopyLinkForRefer = () => {
        // Copy the referral link to the clipboard
        navigator.clipboard.writeText(referralLink)
            .then(() => {
                // Show success toast message if link is copied successfully
                toast.success("Referral link copied successfully. You can refer your friends with the copied link.", {
                    autoClose: 2000,
                });
            })
            .catch((error) => {
                // Handle error if clipboard copy fails
                toast.error("Failed to copy the link. Please try again.", {
                    autoClose: 2000,
                });
                console.error("Error copying text to clipboard: ", error);
            });
    };

    return (
        <button
            onClick={CopyLinkForRefer}
            className="capitalize absolute top-[80%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-blue-600 w-full mb-4 rounded-md px-4 py-1 text-base font-semibold mt-auto"
        >
            Refer a friend
        </button>
    );
}

export default SocialShareButton;
