/** @format */

import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { FiUploadCloud } from "react-icons/fi";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../context/firebase";

function Register() {
  const { Register } = useContext(AuthContext);
  const thumbnailImgFront = useRef();
  const thumbnailImgBack = useRef();
  const [selectedButton, setSelectedButton] = useState('agent');
  // const id = 
  const id  = useParams();

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    gender: "",
    phoneNumber: "+92",
    city: "",
    referralName: "",
    referralContact: "",
    referralUserId: "",
    cnicFront: null,
    state:"",
    cnicBack: null,
  });
  const handleRegister = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let fronpage=''
      let backPage =''
      const imageRef = ref(storage, `images/${form.cnicFront.name + Date.now()}`);
      const uploadTask = await uploadBytes(imageRef, form.cnicFront);
     fronpage = await getDownloadURL(uploadTask.ref);
      const imageRef2 = ref(storage, `images/${form.cnicBack.name + Date.now()}`);
      const uploadTask2 = await uploadBytes(imageRef2, form.cnicBack);
      backPage = await getDownloadURL(uploadTask2.ref);
      Register({...form,cnicBack:backPage,cnicFront:fronpage,role:selectedButton});
    }
  };
  const validateForm = () => {
    const newErrors = {};

    if (!form.firstName) newErrors.firstName = "First name is required.";
    if (!form.lastName) newErrors.lastName = "Last name is required.";
    if (!form.email || !/\S+@\S+\.\S+/.test(form.email))
      newErrors.email = "Valid email is required.";
    if (!form.password || form.password.length < 6)
      newErrors.password = "Password must be at least 6 characters.";
    if (!form.gender) newErrors.gender = "Gender is required.";
    if (!form.phoneNumber || form.phoneNumber.length < 13)
      newErrors.phoneNumber = "Valid phone number is required.";
    if (!form.city) newErrors.city = "City is required.";
    if (!form.referralName) newErrors.referralName = "Referral name is required.";
    if (!form.referralContact) newErrors.referralContact = "Referral contact is required.";
    if (!form.state) newErrors.state = "please select your country.";

    if (!form.cnicFront) newErrors.cnicFront = "Front CNIC image is required.";
    if (!form.cnicBack) newErrors.cnicBack = "Back CNIC image is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [errors, setErrors] = useState({});

  const handleFileChange = (e, key) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setForm((prev) => ({ ...prev, [key]: selectedFile }));
    }
  };


  const handleFormChanges =  (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
    if (name === "phoneNumber" && !/^\+?\d*$/.test(value)) return;
  };

  const handleBlur = () => {
    if (!form.phoneNumber.startsWith("+92")) {
      setForm((prev) => ({ ...prev, phoneNumber: "+92" }));
    }
  };
  useEffect(() => {
    if (id?.id) {
      setForm((prevState) => ({
        ...prevState,
        referralUserId: id.id, // Automatically set referralUserId if id exists
      }));
    }
  }, [id]);
  return (
    <div >
      <div className="bg-slate-100 flex items-center justify-center min-h-screen">
        <form action="" className="bg-white p-8 w-1/3 rounded-sm " style={{   backgroundImage: `url('https://img.freepik.com/free-vector/vibrant-summer-ombre-background-vector_53876-105765.jpg')`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",}}>
          <h1 className="capitalize text-lg font-semibold border-b pb-2">
            Register
          </h1>
          <div className="flex gap-4 items-center justify-between  mt-4">
  <div className="grow">

<label
            htmlFor=""
            className="block text-sm capitalize font-semibold my-2"
            >
first name
          </label>
          <input
            type="text"
            name="firstName"
            onChange={handleFormChanges}
            value={form.firstName}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
            placeholder="first name"
            id="firstname"
            />

            {errors.firstName && <span className="text-red-500 text-sm">{errors.firstName}</span>}
            </div>

            <div className="grow">

            
<label
            htmlFor=""
            className="block text-sm capitalize font-semibold my-2"
          >
           last name
          </label>
          <input
            type="text"
            name="lastName"
            onChange={handleFormChanges}
            value={form.lastName}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
            placeholder="last name"
            id="lastName"
          />
          {errors.lastName && <span className="text-red-500 text-sm">{errors.lastName}</span>}
          </div>
</div>
          <label
            htmlFor=""
            className="block text-sm capitalize font-semibold my-2"
          >
            Email*
          </label>
          <input
            type="email"
            name="email"
            onChange={handleFormChanges}
            value={form.email}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
            placeholder="email"
            id="email"
          />
          {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
          <label
            htmlFor=""
            className="block text-sm capitalize font-semibold my-2"
          >
            password*
          </label>
          <input
            type="password"
            name="password"
            onChange={handleFormChanges}
            value={form.password}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
            placeholder="password"
            id="password"
          />
          {errors.password && <span className="text-red-500 text-sm">{errors.password}</span>}

<p className="text-center mt-4">
Private information for admin use
</p>

<div className="flex items-center justify-between gap-4 mt-4" >
<FormControl sx={{  minWidth: 120 }} size="small" className="grow bg-slate-50">
      <InputLabel id="demo-select-small-label " className="capitalize">gender</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={form.gender}
        label="gender"
        name="gender"
        onChange={handleFormChanges}
      >

        <MenuItem value={'male'}>male</MenuItem>
        <MenuItem value={'female'}>female</MenuItem>

      </Select>
    {errors.gender && <span className="text-red-500 text-sm">{errors.gender}</span>}


    </FormControl>
    <div className="grow">
      <input
        type="text"
        name="phoneNumber"
        id="phoneNumber"
        value={form.phoneNumber}
        onChange={handleFormChanges}
        onBlur={handleBlur}
        maxLength={13} 
        placeholder="+92XXXXXXXXXX"
        className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
        />
      {errors.phoneNumber && <span className="text-red-500 text-sm">{errors.phoneNumber}</span>}
        </div>
</div>
<div className="flex items-center justify-between gap-4 mt-4" >
<FormControl sx={{  minWidth: 120 }} size="small" className="grow bg-slate-50">
      <InputLabel id="demo-select-small-label " className="capitalize">pakistan</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small-2"
        value={form.state}
        name="state"
        label="gender"
        onChange={handleFormChanges}
      >
        <MenuItem value={'pakistan'}>pakistan</MenuItem>
      </Select>
    {errors.state && <span className="text-red-500 text-sm">{errors.state}</span>}
    </FormControl>
  <div className="grow">

      <input
        type="text"
        name="city"
        value={form.city}
        id="city"
        onChange={handleFormChanges}
        placeholder="enter city"
        className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
        />
      {errors.city && <span className="text-red-500 text-sm">{errors.city}</span>}
        </div>
</div>
<p className="text-center mt-4">
Referral information for verification
</p>
<div className="flex items-center gap-4 justify-between  mt-4">
  <div className="grow "> 

          <input
            type="text"
            name="referralName"
            onChange={handleFormChanges}
            value={form.referralName}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
            placeholder="enter referral name"
            id="referralName"
            />
{errors.referralName && <span className="text-red-500 text-sm">{errors.referralName}</span>}
            </div>
            <div className="grow">

          <input
            type="text"
            name="referralContact"
            onChange={handleFormChanges}
            value={form.referralContact}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full"
            placeholder="enter refferal contact"
            id="referralContact"
          />
          {errors.referralContact && <span className="text-red-500 text-sm">{errors.referralContact}</span>}
          </div>
</div>
          <input
            type="text"
            name="referralUserId"
            onChange={handleFormChanges}
            defaultValue={id?.id||form.referralUserId}
            disabled={id?.id}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 focus:border outline-none bg-slate-50 w-full mt-4"
            placeholder="enter refferal user id"
            id="referralUserId"
          />
          {errors.referralUserId && <span className="text-red-500 text-sm">{errors.referralUserId}</span>}
          <p className="text-center mt-4">
          Females can hide their picture in CNIC
</p>

<label className="block text-sm capitalize font-semibold my-2">
CNIC Front Image: *
        </label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, "cnicFront")}
          id="cnicFront"
          ref={thumbnailImgFront}
          hidden
        />
        <label
          className="flex items-center justify-start border-blue-200 bg-slate-50 border  p-3 text-base transition-all gap-3 hover:bg-blue-200 hover:shadow-2xl capitalize font-semibold my-2 cursor-pointer w-full"
          onClick={() => thumbnailImgFront.current.click()}
        >
          <FiUploadCloud />
          <span>Select Image</span>
        </label>
        {errors.cnicFront && <span className="text-red-500 text-sm">{errors.cnicFront}</span>}
<label className="block text-sm capitalize font-semibold my-2 ">
CNIC back Image: *
        </label>
        <input
          type="file"
          id="cnicBack"
          onChange={(e) => handleFileChange(e, "cnicBack")}
          ref={thumbnailImgBack}
          hidden
        />
        <label
          className="flex items-center justify-start border-blue-200 border p-3 text-base transition-all gap-3 hover:bg-blue-200 hover:shadow-2xl capitalize font-semibold my-2 cursor-pointer w-full bg-slate-50"
          onClick={() => thumbnailImgBack.current.click()}
        >
          <FiUploadCloud />
          <span>Select Image</span>
        </label>
        {errors.cnicBack && <span className="text-red-500 text-sm">{errors.cnicBack}</span>}
        <div className="flex mt-4 items-center justify-between">
      <button
            className="border-2 border-[#5800e8]  grow"
        style={{
          backgroundColor: selectedButton === 'agent' ? '#5800e8' : '',
          color: selectedButton === 'agent' ? 'white' : '#5800e8',
          padding: '10px 20px',
          cursor: 'pointer',
        }}
        onClick={(e) => {e.preventDefault();setSelectedButton('agent')}}
      >
work as marketer
      </button>
      <button
      className="border-2 border-[#5800e8]  grow"
        style={{
          backgroundColor: selectedButton === 'manager' ? '#5800e8' : '',
          color: selectedButton === 'manager' ? 'white' : '#5800e8',
          padding: '10px 20px',
          cursor: 'pointer',
        }}
        onClick={(e) => {e.preventDefault();setSelectedButton('manager')}}
      >
       work as manager
      </button>
    </div>
          <button
            className="uppercase w-full bg-black py-2 text-white rounded-md mt-4"
            onClick={handleRegister}
          >
            register
          </button>
          <p className="first-letter:uppercase mt-4 ">
            already have an account?
            <Link
              to={"/"}
              className="first-letter:uppercase text-blue-600 capitalize ms-2"
            >
              sign in
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Register;
