/** @format */
import * as React from "react";
import { TbEdit } from "react-icons/tb";
import { MdContentCopy } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosEye } from "react-icons/io";
import { IoMdSearch } from "react-icons/io";

import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";

import { URI } from "../URL";
import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
// import { port } from "../../Data";
function PendingProducts() {
  //   const { data, deleteProduct } = useFetchData();
  const [loading, setLoading] = useState(false);
  const { getpendingProducts, pendingProducts } = useContext(AuthContext);
  const navigate = useNavigate();
  const approveProduct = async (uid) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${URI}/api/products/${uid}/approve`,
        {}, // Pass an empty object if there is no data to send
        {
          headers: {
            Authorization: token, // Include 'Bearer' if the API requires it
          },
        }
      );
      if (response.status === 200) {
        getpendingProducts();
      }
    } catch (err) {
      console.error(
        "Approval failed",
        err.response ? err.response.data : err.message
      );
    }
  };
  const deleteProduct = async (id) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.delete(`${URI}/api/products/${id}/delete`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (response.status === 200) {
        await getpendingProducts();
      }

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getpendingProducts();
  }, []);
  return (
    <div className="px-6">
      <div className="flex items-center justify-start gap-3 my-3">
        <h1 className="text-lg font-semibold capitalize my-2">products</h1>
        <div className="border flex items-stretch justify-center ps-2 overflow-hidden rounded-md h-10">
          <input
            type="search"
            name=""
            id=""
            placeholder="search here"
            className="  placeholder:capitalize placeholder:text-slate-500 placeholder:font-medium  outline-none "
          />
          <button className="bg-black p-3 text-white  h-full  text-base">
            <IoMdSearch />
          </button>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start">
        <div className="flex min-w-max gap-4 bg-slate-100 w-full py-2 px-4 items-center  justify-between">
          <p className="text-center w-20 capitalize font-semibold ">#</p>
          <p className="text-center w-20 capitalize font-semibold ">title</p>
          <p className="text-start w-40 capitalize font-semibold ">
            description
          </p>

          <p className="text-center w-20 capitalize font-semibold ">price</p>
          <p className="text-center w-20 capitalize font-semibold ">image</p>
          <p className="text-center w-20 capitalize font-semibold ">status</p>
          <p className="text-center w-40 capitalize font-semibold ">
            update status
          </p>

          <p className="text-center w-20 capitalize font-semibold ">actions</p>
        </div>
        {loading ? (
          <div className="font-semibold text-2xl text-center w-full mt-10 capitalize">
            laoding...
          </div>
        ) : pendingProducts && pendingProducts.length > 0 ? (
          pendingProducts.map((item, i) => (
            <div className="flex  gap-4 bg-white w-full py-2 px-4 items-center  justify-between" key={i}>
              <p className="text-center w-20 capitalize font-semibold ">
                {i + 1}
              </p>
              <p className=" w-20 capitalize font-medium text-base text-start ">
                {item.name}
              </p>
              <p className="text-start w-40 text-sm  ">
                {item.description}
                description
              </p>

              <p className="text-center w-20 capitalize font-semibold ">
                {item.price}
              </p>

              <p className="text-start w-20 capitalize font-semibold  flex items-center gap-2">
                <img
                  src={
                    item?.image||"https://247salesmanager.com/Store/edffcf09-141c-407b-93fa-a9969a8c041e.jpg"
                  }
                  className="h-10 w-6 object-cover"
                  alt=""
                />
              </p>

              <p className="text-center w-20 capitalize font-semibold ">
                {item.status}
              </p>
              <p className="text-center w-40 capitalize font-semibold ">
                <button
                  className="bg-green-500 px-4 py-2 rounded-md text-white capitalize"
                  onClick={() => approveProduct(item._id)}
                >
                  approve
                </button>
              </p>

              <p className="text-center w-20 capitalize font-semibold flex flex-col items-center justify-center  gap-2">
                <button
                  className="text-sm h-6 grid place-items-center w-6 rounded-full bg-slate-200"
                  onClick={() => navigate(`/admin/update-product/${item._id}`)}
                >
                  <TbEdit />
                </button>

                <button
                  className="text-sm h-6 grid place-items-center w-6 rounded-full bg-red-100 text-red-500"
                  onClick={() => deleteProduct(item._id)}
                >
                  <RiDeleteBin6Line />
                </button>
              </p>
            </div>
          ))
        ) : (
          <div className="font-bold text-3xl text-center w-full mt-10 capitalize">
            <p>no products found</p>
            <button
              className="w-max  bg-black disabled:opacity-50 text-lg px-4 capitalize  disabled:cursor-wait text-white py-2 rounded-md uppercase my-2"
              onClick={() => {
                navigate("/admin/add-product");
              }}
            >
              add product
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PendingProducts;
