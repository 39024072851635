/** @format */

import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import AdminDashboard from "./Components/AdminDashboard";

import { AuthContext } from "./context/AuthContext";
import PrivateRoute from "./Components/PrivateRoute";
import Register from "./pages/Register";
import LayOut from "./Components/Layout";
import Orders from "./Components/OrderList";
import AllProucts from "./Components/Products";
import AddProduct from "./Components/AddProduct";
import UpdateProduct from "./Components/UpdateProduct";
import PendingProducts from "./Components/PendingProducts";
import AgentOrders from "./Components/AgentOrders";
import AgentProducts from "./Components/AgentProducts";
import AgentDashboard from "./Components/AgentDashboard";
import AlertCenter from "./Components/AlertCenter";
import OrderDetail from "./Components/OrderDetail";
import AdminOrders from "./Components/AdminOrders";
import UpdateOrder from "./Components/UpdateOrder";
import UpdateAgentOrder from "./Components/UpdateAgentOrder";
import AccoutnSetting from "./Components/AccoutnSetting";
import PendingUsersPage from "./pages/PendingUsers";
import UserDetailsPage from "./pages/SingleUser";

function App() {
  const { user } = useContext(AuthContext);
  // const user = {
  //   role: "admin",
  // };

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="register/:id?" element={<Register />} />
      <Route path="admin" element={<LayOut />}>
        <Route path="dashboard" element={<AdminDashboard />} />
      <Route path="settings" element={<AccoutnSetting/>}/>
        <Route path="orders" element={<AdminOrders />} />
        <Route path="pending/users" element={<PendingUsersPage />} />
        <Route path="pending/user/:id" element={<UserDetailsPage />} />
        <Route path="products" element={<AllProucts />} />
        <Route path="products/pending" element={<PendingProducts />} />
        <Route path="add-product" element={<AddProduct />} />
        <Route path="update-product/:id" element={<UpdateProduct />} />
        {/* <Route path="/bar" element={<Sidebar />} /> */}
      </Route>
      <Route path="manager" element={<LayOut />}>
      <Route path="settings" element={<AccoutnSetting/>}/>
      <Route path="orders" element={<Orders />} />
      <Route path="update/order/:id" element={<UpdateOrder />} />
    </Route>
      <Route path="agent" element={<LayOut />}>
        <Route path="orders" element={<AgentOrders />} />
        <Route path="alerts" element={<AlertCenter />} />
      <Route path="settings" element={<AccoutnSetting/>}/>
        <Route path="detail/:id" element={<OrderDetail />} />
        <Route path="dashboard" element={<AgentDashboard />} />
        <Route path="products" element={<AgentProducts />} />
        <Route path="update/order/:id" element={<UpdateAgentOrder />} />
      </Route>
    </Routes>
  );
}

export default App;
