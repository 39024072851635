import React, { useState, useRef, useEffect } from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { URI } from '../URL';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../context/firebase';

function UpdateAgentOrder() {
  const thumbnailImg = useRef();
  const [loading,setLoading]=useState(false)
  const orderThumbnailImg = useRef();
const reviewThumbnailImg = useRef();
const feedbackThumbnailImg = useRef();
const navigate=useNavigate()
const [reviewPrice,setReviewPrice]=useState(null)
const [reviewScreenShot,setReviewScreenShot]=useState(null)
const [FeedbackScreenShot,setFeedBackScreenShot]=useState(null)
const [orderScreenShot,setOrderScreenShot]=useState(null)
const [reviewScreenShotPreview, setReviewScreenShotPreview] = useState(null);
const [feedbackScreenShotPreview, setFeedbackScreenShotPreview] = useState(null);
const [orderScreenShotPreview, setOrderScreenShotPreview] = useState(null);

const setReviewScreenShotData = (url) => {
  setReviewScreenShotPreview(URL.createObjectURL(url));
  setReviewScreenShot(url);
};

const setFeedbackScreenShotData = (url) => {
  setFeedbackScreenShotPreview(URL.createObjectURL(url));
  setFeedBackScreenShot(url);
};

const setOrderScreenShotData = (url) => {

  setOrderScreenShotPreview(URL.createObjectURL(url));
  setOrderScreenShot(url);

};


const options = [
  { label: "Ordered", value: "Ordered" },

  { label: "Reviewed", value: "Reviewed" },
  { label: "Feedbacked", value: "Feedbacked" },
  { label: "Cancelled", value: "cancelled" },
 
];
const {id}=useParams()


// State to store form values
  const [formData, setFormData] = useState({
    reviewType: '',
    amzOrderNumber: '',
    buyerPayPal: '',
    buyerName: '',
    orderScreenshot: null,
    comments: '',
  });
  const [age, setAge] = useState('');
  const [file,setFile]=useState(null)
  const [errors, setErrors] = useState({});


const getSingleOrder = async (orderId) => {

  try {
    const response = await axios.get(`${URI}/api/order/${orderId}`);
    // return response.data;
    const data =response.data
    setFormData({...formData, 
      orderScreenshot:data.orderScreenshot,
    amzOrderNumber: data.amzOrderNumber,
    buyerPayPal: data.buyerPayPal,
    buyerName:data.buyerName,
    refundShot:data.refundShot,
    comission:data?.product?.comission,
    comissionWithPictureReview:data?.product?.comissionWithPictureReview,
    comissionWithTextReview:data?.product?.comissionWithTextReview,
    comissionWithVideoReview:data?.product?.comissionWithVideoReview,
    FeedbackScreenShot:data?.FeedbackScreenShot,
    reviewScreenShot:data?.reviewScreenShot,
    AgentStatus:data?.AgentStatus,
    reviewType:data?.reviewType,
    comments: data.comments,})

  } catch (error) {
    console.error('Error fetching the order:', error.message);
    throw error;
  }
};

// Example usage:
useEffect(()=>{
  getSingleOrder(id)
},[id])
  


  const handleChange2 = (event) => {
    setFormData({ ...formData, reviewType: event.target.value });
    setErrors({ ...errors, reviewType: '' });
  };
  const handleAgentReview = (event) => {
    setFormData({ ...formData, AgentStatus: event.target.value });
    setErrors({ ...errors, reviewType: '' });
  };


  // Handle change for inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle file input change for screenshot

  const handleOrderNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
  
    // Format: After 3 digits, add a hyphen, then after 10 digits, add another hyphen
    if (value.length > 3 && value.length <= 10) {
      value = `${value.slice(0, 3)}-${value.slice(3)}`;
    } else if (value.length > 10 && value.length <= 19) {
      value = `${value.slice(0, 3)}-${value.slice(3, 10)}-${value.slice(10, 19)}`;
    }
  
    // Ensure the total length does not exceed 17 characters
    if (value.length > 19) {
      value = value.slice(0, 19);
    }
  
    setFormData({ ...formData, amzOrderNumber: value });
  };

  // Validate form inputs
  const validate = () => {
    let tempErrors = {};

    if (!formData.amzOrderNumber) tempErrors.amzOrderNumber = 'AMZ Order Number is required';
    else if (!/^\d{3}-\d{7}-\d{7}$/.test(formData.amzOrderNumber)) tempErrors.amzOrderNumber = 'Invalid AMZ Order Number format';
    if (!formData.buyerPayPal) tempErrors.buyerPayPal = 'Buyer PayPal is required';
    if (!formData.buyerName) tempErrors.buyerName = 'Buyer Name is required';
    // if (!file) tempErrors.file = 'order screenshot is required';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };
  

  const handleSubmit =async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    if (validate()) {
      setLoading(true)
      let orderScreenshotUrl = null;
      if (orderScreenShot) {
        const orderScreenshotRef = ref(storage, `images/${orderScreenShot.name + Date.now()}`);
        await uploadBytes(orderScreenshotRef, orderScreenShot);
        orderScreenshotUrl = await getDownloadURL(orderScreenshotRef);
      }

      let reviewScreenshotUrl = null;
      if (reviewScreenShot) {
        const reviewScreenshotRef = ref(storage, `images/${reviewScreenShot.name + Date.now()}`);
        await uploadBytes(reviewScreenshotRef, reviewScreenShot);
        reviewScreenshotUrl = await getDownloadURL(reviewScreenshotRef);
      }
      let FeedbackScreenShotUrl = null;
      if (FeedbackScreenShot) {
        const FeedbackScreenShotRef = ref(storage, `images/${FeedbackScreenShot.name + Date.now()}`);
        await uploadBytes(FeedbackScreenShotRef, FeedbackScreenShot);
        FeedbackScreenShotUrl = await getDownloadURL(FeedbackScreenShotRef);
      }

      // setFormData({...formData,})
    try {


           const response = await axios.put(
        `${URI}/api/orders/agent/${id}`,
        {
          productId: id,
          ...formData,

          orderScreenshot: orderScreenshotUrl || formData.orderScreenshot,
          FeedbackScreenShot: FeedbackScreenShotUrl,
          reviewScreenShot: reviewScreenshotUrl,
          comissionStatus: "unpaid",
          comission:
            formData.reviewType === "text"
              ? formData.comissionWithTextReview
              : formData.reviewType === "video"
              ? formData.comissionWithVideoReview
              : formData.reviewType === "picture"
              ? formData.comissionWithVideoReview
              : formData.comission||null, // Default value if no condition is met
        }
        ,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      const data = response.data;
      toast.success("Order is created successfully", {
        autoClose: 2000, // 2000 milliseconds = 2 seconds
      });
      setFormData({
        reviewType: '',
        amzOrderNumber: '',
        buyerPayPal: '',
        buyerName: '',
        orderScreenshot: null,
        comments: '',
      })

      setLoading(false)
      
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
    }
  };

  return (
    <div className='w-10/12 mx-auto mt-5'>
      <p className='bg-blue-400 capitalize w-full p-2 text-white'>update Order</p>
      <form className='grid grid-cols-2 gap-4' onSubmit={handleSubmit}>
        
        {/* Review Type */}
        <div>
          <label className="block text-sm capitalize font-semibold my-2">
            Review Type *
          </label>
          <Box sx={{ minWidth: 120 }}>
  <FormControl fullWidth>
    <InputLabel id="review-type-label">Review Type</InputLabel>
    <Select
      labelId="review-type-label"
      id="review-type-select"
      value={formData.reviewType}
      label="Review Type"
      onChange={handleChange2}
    >
      {formData.comissionWithTextReview && (
        <MenuItem value="text">Text: Rs.{formData.comissionWithTextReview}</MenuItem>
      )}
      {formData.comissionWithPictureReview && (
        <MenuItem value="picture">Picture: Rs.{formData.comissionWithPictureReview}</MenuItem>
      )}
      {formData.comissionWithVideoReview && (
        <MenuItem value="video">Video: Rs.{formData.comissionWithVideoReview}</MenuItem>
      )}
    </Select>
  </FormControl>
</Box>

          {errors.reviewType && <p className="text-red-500 text-sm">{errors.reviewType}</p>}
        </div>
        
        {/* AMZ Order Number */}
        <div>
          <label className="block text-sm capitalize font-semibold my-2">
            AMZ Order Number *
          </label>
          <input
  type="text"
  name="amzOrderNumber"
  value={formData.amzOrderNumber}
  onChange={handleOrderNumberChange}
  className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
  placeholder="123-4567890-1234567"
  maxLength="19"
/>

          {errors.amzOrderNumber && <p className="text-red-500 text-sm">{errors.amzOrderNumber}</p>}
        </div>

        {/* Buyer PayPal */}
        <div>
          <label className="block text-sm capitalize font-semibold my-2">
            Buyer PayPal *
          </label>
          <input
            type="text"
            name="buyerPayPal"
            value={formData.buyerPayPal}
            onChange={handleChange}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
            placeholder="Buyer PayPal"
          />
          {errors.buyerPayPal && <p className="text-red-500 text-sm">{errors.buyerPayPal}</p>}
        </div>

        {/* Buyer Name */}
        <div>
          <label className="block text-sm capitalize font-semibold my-2">
            Buyer Name *
          </label>
          <input
            type="text"
            name="buyerName"
            value={formData.buyerName}
            onChange={handleChange}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
            placeholder="Buyer Name"
          />
          {errors.buyerName && <p className="text-red-500 text-sm">{errors.buyerName}</p>}
        </div>
<div>


        {/* Order Screenshot */}
        <div className='border-slate-200 border  my-2 gap-2 flex flex-col items-start justify-start'>

          <label className="block text-sm capitalize font-semibold  bg-slate-200 p-4 w-full">
            Order Screenshot
          </label>
          {
            orderScreenShotPreview? <img src={orderScreenShotPreview} alt='order screenshot' className='w-auto object-fill h-52'/>: <img src={formData.orderScreenshot} alt='order screenshot' className='w-auto object-fill h-52'/>
          }
         
          <input
            type="file"
            ref={orderThumbnailImg}
            onChange={(e) => setOrderScreenShotData(e.target.files[0])}
            hidden
          />
          <div className='flex items-center justify-center border-dashed border border-blue-500 h-28 w-full rounded-lg hover:cursor-pointer mt-2' onClick={() => orderThumbnailImg.current.click()}> 
<p className='text-blue-600 capitalize'>
  select image by clicking here
</p>
          </div>
       
        </div>
        {errors.file && <p className="text-red-500 text-sm">{errors.file}</p>}
        <div className='border-slate-200 border  my-2 gap-2 flex flex-col items-start justify-start'>

<label className="block text-sm capitalize font-semibold  bg-slate-200 p-4 w-full">
  refund Screenshot
</label>
{
 formData?.refundShot?<img src={formData.refundShot} alt='refund screenshot' className='w-auto object-fill h-52'/>
:<p className='h-52'>N/A</p>
}


</div>
        </div>
        <div>


{/* Order Screenshot */}
<div className='border-slate-200 border  my-2 gap-2 flex flex-col items-start justify-start'>

  <label className="block text-sm capitalize font-semibold  bg-slate-200 p-4 w-full">
  Rvw Screenshot
  </label>
  {
    reviewScreenShotPreview?<img src={reviewScreenShotPreview} alt='order screenshot' className='w-auto object-fill h-52'/>:formData.reviewScreenShot?<img src={formData.reviewScreenShot} alt='order screenshot' className='w-auto object-fill h-52'/>:<div className='h-52'></div>
    // formData?.reviewScreenShot?:
  }
  
  <input
    type="file"
    ref={reviewThumbnailImg}
    onChange={(e) => setReviewScreenShotData(e.target.files[0])}
    hidden
  />
  <div className='flex items-center justify-center border-dashed border border-blue-500 h-28 w-full rounded-lg hover:cursor-pointer mt-2' onClick={() => reviewThumbnailImg.current.click()}> 
<p className='text-blue-600 capitalize'>
select image by clicking here
</p>
  </div>

</div>
{errors.file && <p className="text-red-500 text-sm">{errors.file}</p>}
<div className='border-slate-200 border  my-2 gap-2 flex flex-col items-start justify-start'>

  <label className="block text-sm capitalize font-semibold  bg-slate-200 p-4 w-full">
  Feedback Screenshot
  </label>
  {
    feedbackScreenShotPreview?<img src={feedbackScreenShotPreview} alt='order screenshot' className='w-auto object-fill h-52'/>:formData?.FeedbackScreenShot?<img src={formData?.FeedbackScreenShot} alt='order screenshot' className='w-auto object-fill h-52'/>:<div className='h-52'></div>
    // formData?.FeedbackScreenShot&&
  }
  
  <input
    type="file"
    ref={feedbackThumbnailImg}
    onChange={(e) => setFeedbackScreenShotData(e.target.files[0])}
    hidden
  />
  <div className='flex items-center justify-center border-dashed border border-blue-500 h-28 w-full rounded-lg hover:cursor-pointer mt-2' onClick={() => feedbackThumbnailImg.current.click()}> 
<p className='text-blue-600 capitalize'>
select image by clicking here
</p>
  </div>

</div>
</div>
        {/* Comments */}
        <div className='col-span-2'>
          <label className="block text-sm capitalize font-semibold my-2">
            Comments
          </label>
          <input
            type="text"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
            placeholder="Comment"
          />
        </div>
        <div>
          <label className="block text-sm capitalize font-semibold my-2">
          Change Order Status *
          </label>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="review-type-label">{formData.AgentStatus}</InputLabel>
              <Select
                labelId="review-type-label"
                id="review-type-select"
                value={formData.AgentStatus}
                label="Review Type"
                onChange={handleAgentReview}
              >
                {
                  options.map((option)=>  <MenuItem value={option.value}>{option.label}</MenuItem>
                  )
                }
              
              
              </Select>
            </FormControl>
          </Box>
          {errors.reviewType && <p className="text-red-500 text-sm">{errors.reviewType}</p>}
        </div>

        {/* Buttons */}
        <div className='block col-start-1 mt-4'>
          <button onClick={()=>navigate(-1)} type="button" className='bg-slate-200 p-4 capitalize rounded-md'>
            Cancel
          </button>
          <button type="submit" className='bg-blue-300 p-4 ms-4 rounded-md capitalize disabled:opacity-70' disabled={loading}>
            {
              loading?"submitting...":"submit"
            }
            
          </button>
        </div>
      </form>
    </div>
  );
}

export default UpdateAgentOrder;
