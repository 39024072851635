import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { URI } from '../URL';
import { useNavigate, useParams } from 'react-router';
import { FiUploadCloud } from 'react-icons/fi';
import { storage } from '../context/firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';

function UpdateOrder() {
    const [file, setFile] = useState(null);
    const navigate=useNavigate()
    const {user}=useContext(AuthContext)
const {id}=useParams()
const thumbnailImg = useRef();
const [order,setOrder]=useState(null)
const [loading,setLoading]=useState(false)
const [selectedValue, setSelectedValue] = useState('');
const [refundShot,setRefundShot]=useState(null)
const [status,setStatus]=useState('')
const handleRefundShot=(e)=>{
  setFile(e.target.files[0])
  setRefundShot(URL.createObjectURL(e.target.files[0]));
}

const handleSelectChange = (event) => {
  setSelectedValue(event.target.value);
};
const handleStatusChange=(e)=>{
  setStatus(e.target.value)
}

const updateOrder = async (orderId) => {
    try {
      setLoading(true)
      // Base URL of the API
      let downloadUrl=""
      if(file){

          const mainref = ref(storage, `imges/${file.name + Date.now()}`);
          const uploadTask = await uploadBytes(mainref, file);
           downloadUrl = await getDownloadURL(uploadTask.ref);
        }

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      };
  
      // Make the PUT request to update the order
      const response = await axios.put(`${URI}/api/orders/${orderId}`, {status:selectedValue,refundShot:downloadUrl||"",comissionStatus:status||order?.comissionStatus}, { headers });
      toast.success('Updated successfully!', {
        delay: 500, 
      });
  setLoading(false)
    } catch (error) {
      // Handle errors
      setLoading(false)
      if (error.response) {
        // Server responded with a status other than 200 range
        console.error("Error response:", error.response.data);
        throw new Error(error.response.data.error || "Failed to update order");
      } else if (error.request) {
        // Request was made but no response was received
        console.error("No response received:", error.request);
        throw new Error("No response from server");
      } else {
        // Something else happened
        console.error("Error setting up the request:", error.message);
        throw new Error(error.message);
      }
    }
  };
    const getOrderById = async (orderId) => {
        try {
          // Make the GET request
          const response = await axios.get(`${URI}/api/order/${orderId}`);
      
          // Return the order data
          setOrder(response.data)

        } catch (error) {
          // Handle errors
          if (error.response) {
            // Server responded with a status other than 200 range
            console.error("Error response:", error.response.data);
            throw new Error(error.response.data.error || "Failed to fetch order");
          } else if (error.request) {
            // Request was made but no response was received
            console.error("No response received:", error.request);
            throw new Error("No response from server");
          } else {
            // Something else happened
            console.error("Error setting up the request:", error.message);
            throw new Error(error.message);
          }
        }
      };
useEffect(()=>{
    getOrderById(id)
},[id])
    const options = [
        { label: "On-Hold", value: "On-Hold" },
        { label: "Refund Delayed", value: "Refund Delayed" },
        { label: "Review Deleted", value: "Review Deleted" },
        { label: "Feedback Sent", value: "Feedback Sent" },
        { label: "Sent to Seller", value: "Sent to Seller" },
        { label: "Feedback Refund", value: "Feedback Rfnd" },
        { label: "Review Awaited", value: "Review Awaited" },
        { label: "Refunded", value: "Refunded" }
      ];
   

  return (
    <>
    {
        order&&    <div className="bg-white p-6">
        <h1 className="text-2xl font-bold mb-4">Update Order</h1>
        <div className="grid grid-cols-2 gap-8">
          {/* Product Image and Details */}
          <div>
            
            <div className="mt-4">
              <p className="font-medium capitalize text-base">AMZ Marketplace:</p>
              <p className="font-normal capitalize text-sm">United States</p>
            </div>
            <div className="mt-4">
              <p className="font-medium capitalize text-base">agent side order status:</p>
              <p className="font-normal capitalize text-sm">{order?.AgentStatus}</p>
            </div>
            <div className="mt-4">
              <p className="font-medium capitalize text-base">buyer paypal</p>
              <p className="font-normal capitalize text-sm">{order.buyerPayPal}</p>
            </div>
            {
              user?.role==="admin"&&(<>
            <div className="mt-4">
              <p className="font-medium capitalize text-base">refferal:</p>
              <p className="font-normal capitalize text-sm">{order?.agent?.referralUserId?.firstName} {order?.agent?.referralUserId?.lastName}</p>
            </div>
            <div className="mt-4">
            <p className="font-medium capitalize text-base">Referral Commission</p>
<p className="font-normal capitalize text-sm">
  Rs. {(order.comission * 0.02).toFixed(2)}
</p>

            </div>
            <div className="mt-4">
  <p className="font-medium capitalize text-base">Agent Commission </p>
  <p className="font-normal capitalize text-sm">
    Rs. {(order.comission * 0.78).toFixed(2)}
  </p>
</div>

            <div className="mt-4">
              <p className="font-medium capitalize text-base">platform fee</p>
              <p className="font-normal capitalize text-sm">Rs.{(order.comission * 0.2).toFixed(2)}</p>
            </div>
            </>)
            }
           
            <p className="font-medium capitalize text-base mt-5">order screenshot:</p>
            <img
              src={order?.orderScreenshot||"https://www.lfjfreebies.org/store/images/US-195926_sz33i13jls_1730926601.webp"}
              className="h-52 object-contain w-auto border border-slate-200"
              alt="Product"
            />
               <div className='mt-4'>
          <label
            htmlFor=""
            className="block text-sm capitalize font-semibold my-2"
            >
            refund screenshot: 
          </label>
            {
             refundShot?<img alt='refund shot' src={refundShot} className="h-52 object-contain w-auto border border-slate-200"/>
:             order?.refundShot&&<img alt='refund shot' src={order.refundShot} className="h-52 object-contain w-auto border border-slate-200"/>
            }
          <input
            type="file"
            name=""
            id=""
            onChange={handleRefundShot}
            ref={thumbnailImg}
            hidden
          />

          <div className='border border-dashed flex items-center justify-center border-blue-500 h-20 w-full rounded-lg' onClick={() => thumbnailImg.current.click()}>
            <p className='text-blue-500'>
                select image on clicking here
            </p>
          </div>
        
        </div>
          </div>

          <div>
           
            <div className="mt-4">
              <p className="font-medium capitalize text-base">AMZ Order Number:</p>
              <p className="font-normal capitalize text-sm">{order?.amzOrderNumber}</p>
            </div>
            <div className="mt-4">
              <p className="font-medium capitalize text-base">Buyer Name:</p>
              <p className="font-normal capitalize text-sm">{order?.buyerName}</p>
            </div>
            
            <p className="font-medium capitalize text-base mt-16 ">
            Review Screenshot::</p>
            {
              order?.reviewScreenShot?<img src={order.reviewScreenShot} className='h-52 w-auto object-fill' alt='order screenshot'/>:            <h1 className='h-52 border border-slate-300 p-4'>
              N/A
          </h1>
            }
            <p className="font-medium capitalize text-base mt-5">
            Feedback Screenshot::</p>
            {
              order?.FeedbackScreenShot?<img src={order.FeedbackScreenShot} className='h-52 w-auto object-fill' alt='Feedback screenshot'/>:            <h1 className='h-52 border border-slate-300 p-4'>
              N/A
          </h1>
            }

       
          </div>
        </div>
        <div className='col-span-2'>
            <label className="block text-sm capitalize font-semibold my-2">
              Comments
            </label>
            <input
              type="text"
              name="comments"
              value={order.comments}
              // onChange={handleChange}
              className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
              placeholder="Comment"
            />
          </div>
          <div className="mb-4">
       <label className="block font-medium mb-2">{"Change Order Status *"}</label>
        <select
          className="w-full p-2 border border-gray-300 rounded-md"
          value={selectedValue||order.status}
          onChange={handleSelectChange}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {user?.role==="admin"&&(
     <div className="mb-4">
     <label className="block font-medium mb-2">{"commission *"}</label>
      <select
        className="w-full p-2 border border-gray-300 rounded-md"
        value={status||order?.comissionStatus}
        onChange={handleStatusChange}
        disabled={order?.comissionStatus==="paid"}
      >
      
          <option  value={'paid'}>
            paid
          </option>
          <option  value={'unpaid'}>
            unpaid
          </option>
        
      </select>
    </div>
      )

      }
 
      
  
      <button onClick={()=>navigate(-1)} type="button" className='bg-slate-200 p-4 capitalize rounded-md'>
            Cancel
          </button>
          <button disabled={loading} type="submit" className='bg-blue-300 p-4 ms-4 rounded-md capitalize' onClick={(e)=>{e.preventDefault();updateOrder(id)}}>
            {
              loading?"submitting...":"submit"
            }
            
          </button>
      </div>
    }
    </>

  );
}

export default UpdateOrder;
