/** @format */

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { URI } from "../URL";
import { CiImageOn } from "react-icons/ci";
import { HiDotsVertical } from "react-icons/hi";
import { useNavigate } from "react-router";
import { RxCross2 } from "react-icons/rx";

function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popUpImage ,setPopUpImage]=useState(null)
  const { user, formatTimestamp } = useContext(AuthContext);

  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const orderStatuses = ["submitted", "verified", "reviewed", "completed"];

  const getOrders = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${URI}/api/manager/orders`, {
        headers: {
          Authorization: token,
        },
      });
      setOrders(response.data);
    } catch (err) {
      console.error(
        "Login failed",
        err.response ? err.response.data : err.message
      );
    }
  };
  const navigate=useNavigate()
  const [activeReviewType,setActiveReviewType]=useState()
  const fetchOrders = async (filter) => {
    const url = `${URI}/api/orders/filtered?AgentStatus=${filter}`;
    const token = localStorage.getItem("token"); // Replace with your actual token
    setActiveReviewType(filter)
    setLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `${token}`, // Add token in the Authorization header
          "Content-Type": "application/json", // Optional, ensures JSON request
        },
      });
  
      // Handle the response
      setOrders(response.data);
    } catch (error) {
      // Handle errors
      console.error("Error fetching orders:", error.response?.data || error.message);
    }finally{
      setLoading(false)
    }
  };
  const options = [
    { label: "Ordered", value: "Ordered" },
    { label: "submitted", value: "submitted" },
  
    { label: "Reviewed", value: "Reviewed" },
    { label: "Feedbacked", value: "Feedbacked" },
    { label: "Cancelled", value: "cancelled" },
   
  ];

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <div className="bg-slate-200 px-6">
      <div>
        <h1 className=" capitalize text-xl font-semibold">all orders</h1>
        <h1 className="first-letter:uppercase">
          you have {orders.length} {orders.length > 1 ? "orders" : "order"}
        </h1>
      </div>
      <div className="flex items-center justify-start my-5 gap-3">
        
        {options.map((review) => (
          <p
            key={review.title}
            onClick={() => fetchOrders(review.value)}
            className={`px-2 py-1 cursor-pointer transition-all duration-300 border rounded-lg text-sm ${
              activeReviewType === review.value
                ? "bg-blue-600 text-white border-blue-600"
                : "hover:bg-blue-600 hover:text-white border-blue-600"
            }`}
          >
            {review.label}
          </p>
        ))}
      </div>
      <div className="w-full my-5">
        <div className="flex items-center flex-col justify-between bg-white rounded-sm shadow-slate-300 shadow-md py-4 grow-[2]">
        <div className="flex gap-3 w-full overflow-x-auto flex-col px-2">
  {(user?.role === "manager" || user?.role === "admin") && orders?.length > 0 ? (
    orders.map((order,i) => (
       <div className="w-full border-b last:border-0" key={i}>
                     <div className="flex items-center justify-between px-6 py-2 bg-blue-200">
                       <p>ORD# {order?._id}</p>
                       <p>Last Update: {formatTimestamp(order?.updatedAt)}</p>
                       {
                         order?.AgentStatus==="cancelled"&& <p className="bg-red-500 text-white px-2 py-1">
                         order cancelled
                         </p>
                       }
                      
                       <div className="relative">
                         <button
                         disabled={order?.AgentStatus==="cancelled"}
                           onClick={() =>
                             setSelectedOrderId(
                               selectedOrderId === order._id ? null : order._id
                             )
                           }
                           className="text-gray-600 hover:text-gray-800"
                         >
                           <HiDotsVertical />
                         </button>
                         {selectedOrderId === order._id && (
                           <div className="absolute top-5 right-0 bg-white border border-gray-300 rounded-md shadow-md flex flex-col">
                          
                             <button
                               className="text-black px-4 py-2 hover:bg-gray-100 text-nowrap capitalize"
                               onClick={() =>
                                 // handleUpdateOrder(order._id, "cancelled")
                                 navigate(`/manager/update/order/${order._id}`)
                               }
                             >
     update Order
                             </button>
                           </div>
                         )}
                       </div>
                     </div>
                     <div className="grid grid-cols-4 p-4 items-center bg-white">
                       <div>
                         <img
                           src={order?.product?.image}
                           alt="product img"
                           className="h-32 w-auto"
                         />
                       </div>
                       <div>
                         <div className="font-medium">Marketplace: {order?.product?.marketPlace}</div>
                         <div className="font-medium">
                           AMZ Order #:{" "}
                           <span className="font-light text-sm">
                             {order?.amzOrderNumber}
                           </span>
                         </div>
                         <div className="font-medium">
                           Buyer PP:{" "}
                           <span className="font-light text-sm">
                             {order?.buyerPayPal}
                           </span>
                         </div>
                         <div className="font-medium">
                           Buyer Name:{" "}
                           <span className="font-light text-sm">{order?.buyerName}</span>
                         </div>
                       </div>
                       <div>
                         <div className="font-medium">
                           Manager:{" "}
                           <span className="font-light text-sm">
                             {order?.product?.vendor?.firstName +" " +order?.product?.vendor?.lastName }
                           </span>
                         </div>
                         <div className="font-medium">
                           Commission RATING: Rs.300
                         </div>
                         <div className="font-medium flex items-center justify-start gap-2">Screenshots:  
                       {
                         
                        order?.orderScreenshot&& <button className="text-xs font-medium flex items-center justify-center bg-red-500 p-1 text-white rounded-md" onClick={()=>setPopUpImage(order.orderScreenshot)}>  <CiImageOn /> <span> order</span></button>
                 
                       }
                       {
                         order?.reviewScreenShot&&
        <button className="text-xs font-medium flex items-center justify-center bg-red-500 p-1 text-white rounded-md" onClick={()=>setPopUpImage(order.reviewScreenShot)}>  <CiImageOn /> <span> review</span></button>
     
                       }
                       {
                         order?.FeedbackScreenShot&&
        <button className="text-xs font-medium flex items-center justify-center bg-red-500 p-1 text-white rounded-md" onClick={()=>setPopUpImage(order.FeedbackScreenShot)}>  <CiImageOn /> <span> Feedback</span></button>
     
                       }
                             </div>
                             {
                         popUpImage&&     <div>
                         <div className="fixed top-0 left-0 w-full h-full bg-[#00000057]">
                         </div>
                       <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[70vh]">
                           <button className="absolute -top-2 -right-2 rounded-full p-2 bg-white text-black" onClick={()=>setPopUpImage(null)}>
                           <RxCross2 />
                           </button>
                      
         
                         <img src={popUpImage} className=" h-full w-auto " alt="new "/>
                           </div>
                         
                     
                         </div>
       
                       }
                       </div>
                       <div className="w-64 mx-auto mt-5"></div>
                     </div>
                   </div>
    ))
  ) : (
    <div className="text-center font-semibold text-xl">No orders found</div>
  )}
</div>

        </div>
      </div>
    </div>
  );
}

export default Orders;
