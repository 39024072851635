import React from 'react';
import { MenuItem, TextField } from '@mui/material';

const MarketplaceDropdown = ({ form, handleChange }) => {
  const marketplaceOptions = ["US", "UK", "DE", "IT", "ES", "CA", "AU"];

  return (
    <div>
      <label className="block text-sm capitalize font-semibold my-2">
        market place
      </label>
      <TextField
        select
        name="marketPlace"
        value={form.marketPlace}
        onChange={handleChange}
        variant="outlined"
        className="w-full bg-slate-50"
        placeholder="Select Market Place"
        InputProps={{
          className: " placeholder:capitalize", // Optional Tailwind classes for consistency
        }}
      >
        {marketplaceOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default MarketplaceDropdown;
