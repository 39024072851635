/** @format */

import React, { useContext, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { MdDashboard } from "react-icons/md";
import { useNavigate } from "react-router";
import { MdShoppingCart } from "react-icons/md";
import { IoSettings } from "react-icons/io5";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaBell } from "react-icons/fa";
import { AuthContext } from "../context/AuthContext";
import SocialShareButton from "../CompnentsT/ShareButton";
import { HiMiniUsers } from "react-icons/hi2";
const Sidebar = () => {
  
  const [openMenu, setOpenMenu] = useState(null);
  const { user, logout } = useContext(AuthContext);

  const navigate = useNavigate();
  const toggleMenu = (menuId) => {
    setOpenMenu(openMenu === menuId ? null : menuId);
  };

  return (
    <div className="w-64 h-screen overflow-y-scroll bg-gray-800 text-secondary1 fixed">
      {
        user?.role&&  ( <ul className="relative h-full ">
        {(user.role === "admin" || user.role === "manager") && (
          <button
            onClick={() => {

              navigate("/admin/dashboard");
              toggleMenu(); // Assuming toggleMenu is a function to close the menu
            }}
            className="flex items-center justify-start gap-3 capitalize p-4 w-full text-left hover:text-white text-slate-200"
          >
            <span>
              <MdDashboard />
            </span>
            <span>Dashboard</span>
          </button>
        )}

        {user.role === "agent" && (
          <button
            onClick={() => {

              navigate("/agent/dashboard");
              toggleMenu();
            }}
            className="flex items-center justify-start gap-3 capitalize p-4 w-full text-left  hover:text-white text-slate-200"
          >
            <span>
              <MdDashboard />
            </span>
            <span>dashboard</span>
          </button>
        )}
        {
          <button
            onClick={() => {

              navigate("/agent/alerts");
              toggleMenu();
            }}
            className="flex items-center justify-start gap-3 capitalize p-4 w-full text-left  hover:text-white text-slate-200"
          >
            <span>
              <FaBell />
            </span>
            <span>alert center</span>
          </button>
        }
        <button
          onClick={() => {
            if (user.role === "agent") {
              navigate("/agent/orders");
            } else if (user.role === "admin") {
              navigate("/admin/orders");
            }else if (user.role === "manager") {
              navigate("/manager/orders");
            }
            toggleMenu();
          }}
          className="flex items-center justify-start gap-3 capitalize p-4 w-full text-left  hover:text-white text-slate-200"
        >
          <span>
            <FaArrowTrendUp />
          </span>
          <span>orders</span>
        </button>
{
  user.role==='admin'&&
  <button
  onClick={() => navigate('/admin/pending/users')}
  className="flex items-center justify-between p-4 w-full text-left  hover:text-white text-slate-200"
>
  <div className="flex items-center justify-center gap-3">
    <span>
      <HiMiniUsers />
    </span>
    <span>pending users</span>
  </div>
</button>
}
        <li>
          <button
            onClick={() => toggleMenu("allProducts")}
            className="flex items-center justify-between p-4 w-full text-left  hover:text-white text-slate-200"
          >
            <div className="flex items-center justify-center gap-3">
              <span>
                <MdShoppingCart />
              </span>
              <span>products</span>
            </div>
            <span
              className={` ${
                openMenu === "allProducts" ? "rotate-90" : "rotate-0 "
              }  overflow-hidden transition-all flex items-center justify-center duration-300`}
            >
              <IoIosArrowForward />
            </span>
          </button>

          <ul
            className={`pl-4 ${
              openMenu === "allProducts" ? "max-h-screen" : "max-h-0 "
            }  overflow-hidden transition-all duration-300`}
          >
            {
              <li
                className="hover:text-white capitalize text-slate-200"
                onClick={() => {
                  if (user?.role === "admin" || user?.role === "manager")
                    navigate("/admin/products");
                  else if (user?.role === "agent") {
                    navigate("/agent/products");
                  }
                }}
              >
                <button className="block p-2 w-full  text-start capitalize">
                  all products
                </button>
              </li>
            }

            {user?.role === "admin" && (
              <li
                className="hover:text-white capitalize text-slate-200"
                onClick={() => navigate("/admin/products/pending")}
              >
                <button className="block p-2 w-full  text-start capitalize">
                  pending products
                </button>
              </li>
            )}
            {(user.role === "admin" || user.role === "manager") && (
              <li
                className="hover:text-white capitalize text-slate-200"
                onClick={() => navigate("/admin/add-product")}
              >
                <button className="block p-2 w-full  capitalize text-start">
                  add new product
                </button>
              </li>
            )}
           <SocialShareButton/>
           
            <button
              className="capitalize absolute top-[90%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-white bg-blue-400  rounded-md  px-4 py-1 text-base font-semibold mt-auto"
              onClick={() => logout()}
            >
              log out
            </button>
          </ul>
      
              <button
            onClick={() => {
              if (user.role === "agent") {
                navigate("/agent/settings");
              } else if (user.role === "admin" || user.role === "manager") {
                navigate("/admin/settings");
              }else if (user.role === "manager") {
                navigate("/manager/settings");
              }

              toggleMenu(); // Assuming toggleMenu is a function to close the menu
            }}
            className="flex items-center justify-start gap-3 capitalize p-4 w-full text-left hover:text-white text-slate-200"
          >
            <span>
              <IoSettings />
            </span>
            <span> update password</span>
          </button>
        </li>
      </ul>)
      }
   
    </div>
  );
};

export default Sidebar;
