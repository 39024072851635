import { GoArrowLeft } from "react-icons/go";
import { FiUploadCloud } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../context/firebase";
import { URI } from "../URL";
import { AuthContext } from "../context/AuthContext";
import MarketplaceDropdown from "../CompnentsT/MarketplaceDropdown";
import MultiSelectDropdown from "../CompnentsT/MultiSelection";

function AddProduct() {
  const navigate = useNavigate();
  const thumbnailImg = useRef();
  const { user } = useContext(AuthContext);
  const [options, setOptions] = useState([
    "Electronics",
    "Fashion & Apparel",
    "Home & Kitchen",
    "Health & Beauty",
    "Sports & Outdoors",
  ]); // Initial options
  const [selectedOption, setSelectedOption] = useState("");
  const [newOption, setNewOption] = useState("");

  // Handle selection
  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
  };

  // Handle new option input
  const handleAddOption = () => {
    if (newOption.trim() && !options.includes(newOption)) {
      setOptions([...options, newOption]);
      setSelectedOption(newOption);
      setNewOption("");
    }
  };
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [form, setForm] = useState({
    name: "",
    description: "",
    price: null,
    comission: null,
    productLink: "",
    marketPlace: "",
    // comissionWithTextReview: null,
    // comissionWithPictureReview: null,
    // comissionWithVideoReview: null,
    category: "",
    guidelines: [],

    marketerInstructions: [],

    commissionPolicies: [],
  });

  const handleDeleteItem = (type, index) => {
    const updatedItems = form[type].filter((_, i) => i !== index);
    setForm({ ...form, [type]: updatedItems });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImagePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleKeyDown = (e, fieldName, fieldList) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const trimmedValue = form[fieldName].trim();
      if (trimmedValue) {
        setForm({
          ...form,
          [fieldName]: "",
          [fieldList]: [...form[fieldList], trimmedValue],
        });
      }
    }
  };
  const validateForm = () => {
    if (!form.name.trim()) {
      toast.error("Product name is required!");
      return false;
    }
    if (!form.category.trim()) {
      toast.error("Category is required!");
      return false;
    }
    if (!form.price || form.price <= 0) {
      toast.error("Price must be a positive number!");
      return false;
    }
    if (!form.comission || form.comission < 0) {
      toast.error(
        "Commission without review is required and cannot be negative!"
      );
      return false;
    }
    // if (!form.comissionWithTextReview || form.comissionWithTextReview < 0) {
    //   toast.error(
    //     "Commission with text review is required and cannot be negative!"
    //   );
    //   return false;
    // }
    // if (
    //   !form.comissionWithPictureReview ||
    //   form.comissionWithPictureReview < 0
    // ) {
    //   toast.error(
    //     "Commission with image review is required and cannot be negative!"
    //   );
    //   return false;
    // }
    // if (!form.comissionWithVideoReview || form.comissionWithVideoReview < 0) {
    //   toast.error(
    //     "Commission with video review is required and cannot be negative!"
    //   );
    //   return false;
    // }
    if (!file) {
      toast.error("Please select a thumbnail image!");
      return false;
    }
    // const urlPattern = /^(http|https):\/\/[^\s$.?#].[^\s]*$/;
    // if (!form.productLink.trim() || !urlPattern.test(form.productLink)) {
    //   toast.error("Affiliate link must be in a valid http or https format!");
    //   return false;
    // }
    return true;
  };
  const addProduct = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      const imageRef = ref(storage, `images/${file.name + Date.now()}`);
      const uploadTask = await uploadBytes(imageRef, file);
      const downloadUrl = await getDownloadURL(uploadTask.ref);

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${URI}/api/products`,
        { ...form, image: downloadUrl },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 201) {
        toast.success("Product created successfully!");
        setForm({
          name: "",
          description: "",
          price: null,
          category: "",
          productLink: "",
          marketPlace: "",
          comission: null,
          // comissionWithTextReview: null,
          // comissionWithPictureReview: null,
          // comissionWithVideoReview: null,
          guideline: "",
          guidelines: [],
          marketerInstruction: "",
          marketerInstructions: [],
          commissionPolicy: "",
          commissionPolicies: [],
        });
        setFile(null);
        setImagePreview(null);
      }
    } catch (err) {
      toast.error(
        err.response?.data?.message || "Something went wrong, try again!"
      );
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="px-6 bg-slate-100">
      <div className="flex items-center justify-between w-full">
        <h2 className="capitalize font-semibold text-xl">Add Product</h2>
        <button
          className="bg-black uppercase rounded-sm text-white flex gap-1 py-2 px-4 items-center justify-center"
          onClick={() => navigate(-1)}
        >
          <GoArrowLeft />
          <span className="font-semibold text-sm">Back</span>
        </button>
      </div>

      <form
        className="w-1/2 mx-auto bg-white p-4 my-8 rounded-md shadow"
        onSubmit={addProduct}
      >
        <h1 className="text-xl font-semibold border-b py-4 capitalize">
          Product Information
        </h1>

        <label className="block text-sm capitalize font-semibold my-2">
          Product Name*
        </label>
        <input
          type="text"
          name="name"
          value={form.name}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Product name"
        />
  

        <label className="block text-sm capitalize font-semibold my-2">
          Product id*
        </label>
        <input
          type="text"
          name="productId"
          value={form.productId}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Product id"
        />

        <label className="block text-sm capitalize font-semibold my-2">
          Description
        </label>
        <textarea
          name="description"
          value={form.description}
          onChange={handleChange}
          rows={4}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Product description"
        />

        <label className="block text-sm capitalize font-semibold my-2">
          product category *
        </label>
        <input
          type="text"
          name="category"
          value={form.category}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="category"
        />
        {/* <label className="block text-sm capitalize font-semibold my-2">
          market place
        </label>
        <input
          type="text"
          name="marketPlace"
          value={form.marketPlace}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="market place"
        /> */}
        <MarketplaceDropdown form={form} handleChange={handleChange}/>
        {user?.role === "admin" && (
          <>
            <label className="block text-sm capitalize font-semibold my-2">
              affiliate link
            </label>
            <input
              type="text"
              name="productLink"
              value={form.productLink}
              onChange={handleChange}
              className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
              placeholder="affiliate link"
            />
          </>
        )}
<div className="mx-auto mt-10">
  <label
    htmlFor="productCategory"
    className="block mb-2 text-gray-700 text-sm font-medium capitalize"
  >
    Product Category
  </label>
  <select
    name="productCategory"
    id="productCategory"
    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
    value={form.productCategory || ""} // Bind the value to the form state
    onChange={handleChange} // Use the handleChange function
  >
    <option value="" disabled>
      -- Select an option --
    </option>
    {options.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ))}
  </select>

  <div className="flex items-center mt-4">
    <input
      type="text"
      className="flex-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
      placeholder="Add new option"
      value={newOption}
      onChange={(e) => setNewOption(e.target.value)}
    />
    <button
      onClick={handleAddOption}
      className="ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
    >
      Add
    </button>
  </div>
</div>
<MultiSelectDropdown form={form} setForm={setForm}/>

        <label className="block text-sm capitalize font-semibold my-2">
          Price*
        </label>
        <input
          type="number"
          name="price"
          value={form.price}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Price"
        />

        <label className="block text-sm capitalize font-semibold my-2">
          Commission *
        </label>
        <input
          type="number"
          name="comission"
          value={form.comission}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Commission"
        />

        {/* <label className="block text-sm capitalize font-semibold my-2">
          Commission with Text Review*
        </label>
        <input
          type="number"
          name="comissionWithTextReview"
          value={form.comissionWithTextReview}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Commission with Text Review"
        />
        <label className="block text-sm capitalize font-semibold my-2">
          Commission with Image Review*
        </label>
        <input
          type="number"
          name="comissionWithPictureReview"
          value={form.comissionWithPictureReview}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Commission with Text Review"
        />
        <label className="block text-sm capitalize font-semibold my-2">
          Commission with video Review*
        </label>
        <input
          type="number"
          name="comissionWithVideoReview"
          value={form.comissionWithVideoReview}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Commission with Text Review"
        /> */}

        <label className="block text-sm capitalize font-semibold my-2">
          Review Guidelines
        </label>
        <textarea
          name="guideline"
          value={form.guideline}
          onChange={handleChange}
          onKeyDown={(e) => handleKeyDown(e, "guideline", "guidelines")}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Enter a guideline and press Enter"
        />
        <ul className="mt-4 space-y-2">
          {form.guidelines.map((guideline, index) => (
            <li
              key={index}
              className="flex justify-between items-center p-2 bg-blue-100 border rounded-md"
            >
              <span>{guideline}</span>
              <button
                type="button"
                className="text-red-500 font-semibold ml-4"
                onClick={() => handleDeleteItem("guidelines", index)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>

        {/* Marketer Instructions */}
        <label className="block text-sm capitalize font-semibold my-2">
          Instructions for Marketer
        </label>
        <textarea
          name="marketerInstruction"
          value={form.marketerInstruction}
          onChange={handleChange}
          onKeyDown={(e) =>
            handleKeyDown(e, "marketerInstruction", "marketerInstructions")
          }
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Enter an instruction and press Enter"
        />
        <ul className="mt-4 space-y-2">
          {form.marketerInstructions.map((instruction, index) => (
            <li
              key={index}
              className="flex justify-between items-center p-2 bg-green-100 border rounded-md"
            >
              <span>{instruction}</span>
              <button
                type="button"
                className="text-red-500 font-semibold ml-4"
                onClick={() => handleDeleteItem("marketerInstructions", index)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>

        {/* Commission Policies */}
        <label className="block text-sm capitalize font-semibold my-2">
          Commission Policies
        </label>
        <textarea
          name="commissionPolicy"
          value={form.commissionPolicy}
          onChange={handleChange}
          onKeyDown={(e) =>
            handleKeyDown(e, "commissionPolicy", "commissionPolicies")
          }
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Enter a policy and press Enter"
        />
        <ul className="mt-4 space-y-2">
          {form.commissionPolicies.map((policy, index) => (
            <li
              key={index}
              className="flex justify-between items-center p-2 bg-yellow-100 border rounded-md"
            >
              <span>{policy}</span>
              <button
                type="button"
                className="text-red-500 font-semibold ml-4"
                onClick={() => handleDeleteItem("commissionPolicies", index)}
              >
                Delete
              </button>
            </li>
          ))}
        </ul>

        <label className="block text-sm capitalize font-semibold my-2">
          Select Thumbnail Image: *
        </label>
        <input
          type="file"
          onChange={handleFileChange}
          ref={thumbnailImg}
          hidden
        />
        <label
          className="flex items-center justify-start border-blue-200 border w-max p-3 text-base transition-all gap-3 hover:bg-blue-200 hover:shadow-2xl capitalize font-semibold my-2 cursor-pointer"
          onClick={() => thumbnailImg.current.click()}
        >
          <FiUploadCloud />
          <span>Select Image</span>
        </label>
        {imagePreview && (
          <img
            src={imagePreview}
            alt="Preview"
            className="mt-2 h-32 object-cover rounded-md"
          />
        )}

        <button
          type="submit"
          className="w-full bg-black disabled:opacity-50 text-white py-2 rounded-md uppercase mt-4"
          disabled={loading}
        >
          {loading ? "Creating..." : "Create Product"}
        </button>
      </form>
    </div>
  );
}

export default AddProduct;
