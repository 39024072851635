import React,  { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { IoCheckmarkCircle } from "react-icons/io5";
import axios from "axios";
import { URI } from "../URL";

function Sidebar({id}) {

    const [loading,setLoading]=useState(false)
    const [searchbar,setSearchBar]=useState(false)
const [product,setProduct]=useState({})
    const getSingleProduct = async (id) => {
      const token = localStorage.getItem("token");

      try {
    setLoading(true)
        const response = await axios.get(
          `${URI}/api/products/${id}/singleproduct`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        if (response.status === 200) {
          const data = response.data.product;
          setProduct(data)
          // setReviewPrice({comissionWithTextReview:data?.comissionWithTextReview,comission:data?.comission})
    
    setLoading(false)
        }
      } catch (error) {
        setLoading(false)
    
      }
    };



;
  
let data=""
  return (
 
    <>
    <button className=" text-blue-500 font-light " onClick={()=>{setSearchBar(true);getSingleProduct(id)}}>
        (view details)
    </button>
      <div
        onClick={() => setSearchBar((e) => !e)}
        className={
          searchbar
            ? "fixed top-0 left-0 w-full h-screen bg-[#0000007a] z-30"
            : "hidden"
        }
      ></div>
           <div
        className={`md:w-1/2 bg-white ${
          searchbar ? "translate-x-0" : "translate-x-full"
        } h-screen z-50 fixed top-0 w-full overflow-y-scroll transition-all duration-300 p-4 right-0 shadow-lg  py-4`}
      >
      {
        loading?<div className="flex items-center justify-center w-full h-full ">loading...</div>:<>   <div className="grid grid-cols-2 gap-x-8">

        <div className="mt-2">
        <p className="font-medium capitalize text-base">product image:</p>
            <img src={product?.image||"https://www.lfjfreebies.org/store/images/US-195926_sz33i13jls_1730926601.webp"} className="h-[40vh] object-contain w-auto border border-slate-200" alt="img "/>
            <div className="mb-4">
                <p className="font-medium capitalize text-base">AMZ Marketplace:</p>
                <p className="font-normal capitalize text-sm">
                 United States</p>
            </div>
            <div className="mb-4">
                <p className="font-medium capitalize text-base">Sold By:</p>
                <p className="font-normal capitalize text-sm">
                {product?.vendor?.name}</p>
            </div>
            <div className="mb-4">
                <p className="font-medium capitalize text-base">Keyword:</p>
                <p className="font-normal capitalize text-sm">
                Gifts for men(use coupon)</p>
            </div>
            <div className="mb-4">
                <p className="font-medium capitalize text-base">price:</p>
                <p className="font-normal capitalize text-sm">
                Rs. {product?.price} </p>
            </div>
        </div>
      
           
        <div>
    
        <div className="mt-2">
        <p className="font-medium capitalize text-base">AMZ image:</p>
            {/* <img src={product?.image||"https://www.lfjfreebies.org/store/images/US-195926_sz33i13jls_1730926601.webp"} className=" object-contain w-auto border border-slate-200" alt="img "/> */}
            <p className="h-[40vh]">
              N/A
            </p>
            <div className="mb-4">
                <p className="font-medium capitalize text-base">Product Category:
                </p>
                <p className="font-normal capitalize text-sm">
                Electronics</p>
            </div>
            <div className="mb-4">
                <p className="font-medium capitalize text-base">Brand Name:  
                </p>
                <p className="font-normal capitalize text-sm">
                N/A</p>
            </div>
            <div className="mb-4">
                <p className="font-medium capitalize text-base">Keyword:</p>
                <p className="font-normal capitalize text-sm">
                Gifts for men(use coupon)</p>
            </div>
            <div className="mb-4">
                <p className="font-medium capitalize text-base">manager</p>
                <p className="font-normal capitalize text-sm">
                {product?.vendor?.name}
                </p>
            </div>
        </div>
      
        </div>
           </div>
      <div className="pt-2 border-y pb-4 border-y-slate-300 ">
        <h2 className="text-xl">
        Commission Details
    
    
        </h2>
        <p className="text-green-400">No Review Commission:</p>
        <p>Rs.{product?.comission}</p>
      </div>
         <div className="mt-4">
    
       <div>
            {product?.guidelines?.length && <h2 className="font-semibold text-lg" >Review Guidelines</h2>}
            {
product?.guidelines?.map((text,index)=>   <p key={index} className="flex items-center justify-start">
              <span className="text-blue-700">  <IoCheckmarkCircle/></span> <span className="ms-2 font-normal">{text}</span>
                        </p>)
            }
        </div>
       <div>
       {product?.marketerInstructions?.length && (
  <h2 className="font-semibold text-lg">Instructions for Marketer</h2>
)}

            {
product?.marketerInstructions?.map((text,index)=>   <p key={index} className="flex items-center justify-start">
              <span className="text-blue-700">  <IoCheckmarkCircle/></span> <span className="ms-2 font-normal">{text}</span>
                        </p>)
            }
        </div>
       <div>
            {product?.commissionPolicies?.length &&<h2 className="font-semibold text-lg" >Commission Policy</h2>}
            {
product?.commissionPolicies?.map((text,index)=>   <p key={index} className="flex items-center justify-start">
              <span className="text-blue-700">  <IoCheckmarkCircle/></span> <span className="ms-2 font-normal">{text}</span>
                        </p>)
            }
        </div>
    
    
         
         </div></>
      }
 
    
      </div>
    </>
  );
}

export default Sidebar;