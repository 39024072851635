import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TextField, Card, CardContent, Typography, Box, InputAdornment, IconButton } from '@mui/material';
import BasicModal from '../CompnentsT/PopUp';
import axios from 'axios';
import {URI} from "../URL"
import { Visibility, VisibilityOff } from '@mui/icons-material';

const UserDetailsPage = () => {
  const { id :userId} = useParams();
  const navigate = useNavigate()
  // Static data for user details (simulating fetched data)
const [user,setUser] = useState({})
  const [newPassword, setNewPassword] = useState("");
  const [status, setStatus] = useState(user?.status);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleApproveUser = async () => {
    const token = localStorage.getItem('token'); // Retrieve token from local storage
    const url = `${URI}/api/user/${userId}/approve`;

    const requestBody = newPassword ? { password: newPassword } : {};

    try {
      setLoading(true);
      setMessage('');

      const response = await axios.put(
        url,
        requestBody,
        {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
navigate('/admin/pending/users')
      setMessage(response.data.message);
    
    } catch (error) {
      setMessage(error.response?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem('token'); // or however you store your token
      const url = `${URI}/api/pendinguser/${userId}`;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }

        const data = await response.json();
        setUser(data);  // Store user data in state
      } catch (err) {
        setError(err.message);  // Handle error if needed
      }
    };

    fetchUserDetails();
  }, [userId]);

  if (error) return <div>Error: {error}</div>;
  if (!user) return <div>Loading...</div>;
  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleApprove = () => {
    // Logic for approving the user goes here
    alert("User Approved!");
    setStatus("approved");
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handlePasswordUpdate = () => {
    // Logic for updating the password goes here
    alert("Password Updated!");
  };

  return (
    <div className="bg-gray-50 min-h-screen py-6 px-4 sm:px-8">
      <Card className="bg-white shadow-lg rounded-lg max-w-3xl mx-auto p-6">
        <Typography variant="h4" component="h1" className="text-center font-semibold text-gray-800 mb-6">
          User Details
        </Typography>

        <div className="space-y-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div>
              <p><strong>Name:</strong> {user.firstName} {user.lastName}</p>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong className='capitalize'>role:</strong> {user.role}</p>
              <p><strong>Phone:</strong> {user.phoneNumber}</p>
              <p><strong>gender:</strong> {user.gender}</p>
              <p><strong>country:</strong> {user.state}</p>
              <p><strong>city:</strong> {user.city}</p>
              <p><strong>Referral Name:</strong> {user.referralUserId?.firstName + user.referralUserId?.lastName ||user.referralUserId?.name }</p>
              <p><strong>Referral Contact:</strong> {user.referralUserId?.phoneNumber  ||user.referralUserId?.name  }</p>
              <p><strong className='capitalize'>Referral email:</strong> {user.referralUserId?.email  || ""  }</p>
            </div>
            <div>
              <p><strong>CNIC Front:</strong></p>
            <BasicModal image={user?.cnicFront} />
              <p className="mt-4"><strong>CNIC Back:</strong></p>
              <BasicModal image={user?.cnicBack} />
            </div>
          </div>

          <div className="border-t border-gray-200 pt-6">
            <p><strong>Status:</strong> {status}</p>
          </div>

          {/* Password Change Section */}
          <div className="border-t border-gray-200 pt-6">
          <Typography variant="h6" className="font-medium text-gray-800">
        Change Password
      </Typography>
      <TextField
        label="New Password"
        type={showPassword ? 'text' : 'password'}
        variant="outlined"
        fullWidth
        value={newPassword}
        onChange={handlePasswordChange}
        className="mt-2"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handlePasswordUpdate}
        className="mt-4 w-full"
      >
        Update Password
      </Button>
          </div>

          {/* Approve User Section */}
          <div className="border-t border-gray-200 pt-6">
            <Button
              variant="contained"
              color="success"
              onClick={handleApproveUser}
              className="w-full"
            >
              Approve User
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default UserDetailsPage;
