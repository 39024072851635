import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Box } from '@mui/material';
import axios from 'axios';
import { URI } from '../URL';

const PendingUsersPage = () => {

  const [pendingUsers, setPendingUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Get the token from localStorage or any other storage you use
  const token = localStorage.getItem('token'); // assuming token is saved in localStorage

  useEffect(() => {
    const fetchPendingUsers = async () => {

      try {
        const response = await axios.get(`${URI}/api/all/pendingusers`, {
          headers: {
            Authorization: `${token}`  // Passing the token in Authorization header
          }
        });
        setPendingUsers(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch pending users');
        setLoading(false);
      }
    };

    fetchPendingUsers();
  }, [token]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="bg-gray-100 min-h-screen py-6 px-4">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Pending Users</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      {pendingUsers && pendingUsers.length > 0 ? (
  pendingUsers.map((user) => (
    <Card
      key={user._id}
      className="hover:scale-105 transition-transform duration-300 ease-in-out transform"
    >
      <CardContent>
        <div className="space-y-4">
          <Typography
            variant="h6"
            className="text-xl font-semibold text-gray-700"
          >
            {user?.firstName} {user?.lastName} {user?.name}
          </Typography>
          <Typography className="text-sm text-gray-500">
            <strong>Email:</strong> {user?.email}
          </Typography>
          <Typography className="text-sm text-gray-500">
            <strong>Phone:</strong> {user?.phoneNumber}
          </Typography>
        </div>
        <Box mt={4} className="flex justify-end">
          <Link to={`/admin/pending/user/${user?._id}`}>
            <Button
              variant="contained"
              color="primary"
              className="hover:bg-blue-700 transition-colors duration-300"
            >
              View Details
            </Button>
          </Link>
        </Box>
      </CardContent>
    </Card>
  ))
) : (
  <div className="flex items-center justify-center py-10">
    <Typography
      variant="h5"
      className="text-gray-500 text-center font-semibold"
    >
      No users found.
    </Typography>
  </div>
)}

      </div>
    </div>
  );
};

export default PendingUsersPage;
