import axios from "axios";
import { URI } from "../URL";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { HiDotsVertical } from "react-icons/hi";
import { useNavigate } from "react-router";
import { CiImageOn } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";

function AdminOrders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [popUpImage ,setPopUpImage]=useState(null)
  const { user, formatTimestamp } = useContext(AuthContext);
  const [activeReviewType,setActiveReviewType]=useState('submitted')
  const [StatusCom,setStatusCom]=useState('paid')

  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const navigate=useNavigate()
  const fetchOrders = async (filter,comissionStatus=StatusCom) => {
    const url = `${URI}/api/orders/filtered?AgentStatus=${filter}&comissionStatus=${comissionStatus}`;
    setActiveReviewType(filter)
    setStatusCom(comissionStatus)
    const token = localStorage.getItem("token"); // Replace with your actual token
    setLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `${token}`, // Add token in the Authorization header
          "Content-Type": "application/json", // Optional, ensures JSON request
        },
      });
  
      // Handle the response
      setOrders(response.data);
    } catch (error) {
      // Handle errors
      console.error("Error fetching orders:", error.response?.data || error.message);
    }finally{
      setLoading(false)
    }
  };
  const getOrders = async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const response = await axios.get(`${URI}/api/orders/all`, {
        headers: {
          Authorization: token,
        },
      });

      setOrders(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching orders:", err.response?.data || err.message);
    }
  };
  useEffect(() => {
    if(user?.id){

      getOrders(user.id);
    }
  }, [user?.id]);
  const options = [
    { label: "submitted", value: "submitted" },
    { label: "Ordered", value: "Ordered" },
  
    { label: "Reviewed", value: "Reviewed" },
    { label: "Feedbacked", value: "Feedbacked" },
    { label: "Cancelled", value: "cancelled" },
   
  ];
  return (
    <div className="bg-slate-200 px-6">
      <div>
        <h1 className="capitalize text-xl font-semibold">All Orders</h1>
        <h2 className="first-letter:uppercase">
          You have {orders.length} {orders.length > 1 ? "orders" : "order"}
        </h2>
      </div>
      <div className="flex items-center justify-start my-5 gap-3">
        
        {options.map((review) => (
          <p
            key={review.title}
            onClick={() => fetchOrders(review.value)}
            className={`px-2 py-1 cursor-pointer transition-all duration-300 border rounded-lg text-sm ${
              activeReviewType === review.value
                ? "bg-blue-600 text-white border-blue-600"
                : "hover:bg-blue-600 hover:text-white border-blue-600"
            }`}
          >
            {review.label}
          </p>
        ))}
      </div>
      {
        user?.role==='admin'&&      <div className="flex items-center justify-start my-5 gap-3">
        
       
        <p

onClick={() => fetchOrders(activeReviewType,'paid')}
className={`px-2 py-1 cursor-pointer transition-all duration-300 border rounded-lg text-sm ${
  StatusCom === "paid"
    ? "bg-blue-600 text-white border-blue-600"
    : "hover:bg-blue-600 hover:text-white border-blue-600"
}`}
>
paid
</p>
          <p

            onClick={() => fetchOrders(activeReviewType,'unpaid')}
            className={`px-2 py-1 cursor-pointer transition-all duration-300 border rounded-lg text-sm ${
              StatusCom === "unpaid"
                ? "bg-blue-600 text-white border-blue-600"
                : "hover:bg-blue-600 hover:text-white border-blue-600"
            }`}
          >
           unpaid
          </p>
      
      </div>
      }

      <div className="w-full my-5">
        <div className="flex flex-col items-center rounded-sm shadow-md bg-white py-4">
          {loading ? (
            <div className="text-center font-semibold text-2xl capitalize">
              Loading...
            </div>
          ) : orders.length > 0 ? (
            orders.map((order, i) => (
              <div className="w-full border-b last:border-0" key={i}>
              <div className="flex items-center justify-between px-6 py-2 bg-blue-200">
                <p>ORD# {order?._id}</p>
                <p>Last Update: {formatTimestamp(order?.updatedAt)}</p>
                {
                  order?.AgentStatus==="cancelled"&& <p className="bg-red-500 text-white px-2 py-1">
                  order cancelled
                  </p>
                }
               
                <div className="relative">
                  <button
                  disabled={order?.AgentStatus==="cancelled"}
                    onClick={() =>
                      setSelectedOrderId(
                        selectedOrderId === order._id ? null : order._id
                      )
                    }
                    className="text-gray-600 hover:text-gray-800"
                  >
                    <HiDotsVertical />
                  </button>
                  {selectedOrderId === order._id && (
                    <div className="absolute top-5 right-0 bg-white border border-gray-300 rounded-md shadow-md flex flex-col">
                   
                      <button
                        className="text-black px-4 py-2 hover:bg-gray-100 text-nowrap capitalize"
                        onClick={() =>
                          // handleUpdateOrder(order._id, "cancelled")
                          navigate(`/manager/update/order/${order._id}`)
                        }
                      >
update Order
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="grid grid-cols-4 p-4 items-center bg-white">
                <div>
                  <img
                    src={order?.product?.image}
                    alt="product img"
                    className="h-32 w-auto"
                  />
                </div>
                <div>
                  <div className="font-medium">Marketplace: {order?.product?.marketPlace}</div>
                  <div className="font-medium">
                    AMZ Order #:{" "}
                    <span className="font-light text-sm">
                      {order?.amzOrderNumber}
                    </span>
                  </div>
                  <div className="font-medium">
                    Buyer PP:{" "}
                    <span className="font-light text-sm">
                      {order?.buyerPayPal}
                    </span>
                  </div>
                  <div className="font-medium">
                    Buyer Name:{" "}
                    <span className="font-light text-sm">{order?.buyerName}</span>
                  </div>
                </div>
                <div>
                  <div className="font-medium">
                    Manager:{" "}
                    <span className="font-light text-sm">
                      {order?.product?.vendor?.firstName +" " +order?.product?.vendor?.lastName }
                    </span>
                  </div>
                  <div className="font-medium">
                    Commission RATING: Rs.300
                  </div>
                  <div className="font-medium flex items-center justify-start gap-2">Screenshots:  
                {
                  
                 order?.orderScreenshot&& <button className="text-xs font-medium flex items-center justify-center bg-red-500 p-1 text-white rounded-md" onClick={()=>setPopUpImage(order.orderScreenshot)}>  <CiImageOn /> <span> order</span></button>
          
                }
                {
                  order?.reviewScreenShot&&
 <button className="text-xs font-medium flex items-center justify-center bg-red-500 p-1 text-white rounded-md" onClick={()=>setPopUpImage(order.reviewScreenShot)}>  <CiImageOn /> <span> review</span></button>

                }
                {
                  order?.FeedbackScreenShot&&
 <button className="text-xs font-medium flex items-center justify-center bg-red-500 p-1 text-white rounded-md" onClick={()=>setPopUpImage(order.FeedbackScreenShot)}>  <CiImageOn /> <span> Feedback</span></button>

                }
                      </div>
                      {
                  popUpImage&&     <div>
                  <div className="fixed top-0 left-0 w-full h-full bg-[#00000057]">
                  </div>
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[70vh]">
                    <button className="absolute -top-2 -right-2 rounded-full p-2 bg-white text-black" onClick={()=>setPopUpImage(null)}>
                    <RxCross2 />
                    </button>
               
  
                  <img src={popUpImage} className=" h-full w-auto " alt="new "/>
                    </div>
                  
              
                  </div>

                }
                </div>
                <div className="w-64 mx-auto mt-5"></div>
              </div>
            </div>
            ))
          ) : (
            <div className="text-center capitalize font-medium mt-4">
              No order found
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminOrders;
