import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '90%', // Adjust for responsiveness
  maxHeight: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

export default function BasicModal({ image }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* Trigger */}
      <img
        src={image}
        alt="Preview"
        className="w-full rounded-md shadow-sm h-96 object-contain cursor-pointer"
        onClick={handleOpen}
      />

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-image-title"
        aria-describedby="modal-image-description"
      >
        <Box sx={style}>
          <img
            src={image}
            alt="Enlarged View"
            style={{
              width: 'auto',
              height: '80vh',
              objectFit: 'cover',
              borderRadius: '8px',
            }}
          />
        </Box>
      </Modal>
    </div>
  );
}
