/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { URI } from "../URL";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function AgentProducts() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [activeReviewType, setActiveReviewType] = useState("All");
  const [activeMarketplace, setActiveMarketplace] = useState("All");

  const navigate = useNavigate();

  const reviewTypes = [
    { title: "All" },
    { title: "text" },
    { title: "picture" },
    { title: "video" },
    { title: "rating" },
    { title: "no review" },
  ];
  const marketplaceOptions = ["All", "US", "UK", "DE", "IT", "ES", "CA", "AU"];

  // Fetch filtered products based on reviewType and marketplace
  const callApiForMarketplace = async (rating = "", marketplace = "") => {
    setActiveReviewType(rating);
    setActiveMarketplace(marketplace);

    try {
      setLoading(true);
      const response = await axios.get(
        `${URI}/api/products/filter?marketPlace=${marketplace}&reviewType=${rating}`
      );
      setProducts(response.data?.products || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      toast.error("Failed to fetch products. Please try again.");
      setLoading(false);
    }
  };
  const createOrder = async (id) => {
    navigate(`/agent/detail/${id}`);
  };

  const getProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${URI}/api/products/approved`);
      setProducts(response.data || []);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching products:", err);
      toast.error("Failed to fetch approved products. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div className="w-[96%] mx-auto">
      {/* Filters Section */}
      <div className="flex items-center justify-between my-6">
        {/* Review Type Filters */}
        <div className="flex items-center gap-3">
          {reviewTypes.map((review) => (
            <p
              key={review.title}
              onClick={() =>
                callApiForMarketplace(review.title, activeMarketplace)
              }
              className={`px-2 py-1 cursor-pointer transition-all duration-300 border rounded-lg text-sm ${
                activeReviewType === review.title
                  ? "bg-blue-600 text-white border-blue-600"
                  : "hover:bg-blue-600 hover:text-white border-blue-600"
              }`}
            >
              {review.title}
            </p>
          ))}
        </div>

        {/* Marketplace Filters */}
        <div className="flex items-center gap-3">
          {marketplaceOptions.map((place) => (
            <p
              key={place}
              onClick={() => callApiForMarketplace(activeReviewType, place)}
              className={`px-2 py-1 cursor-pointer transition-all duration-300 border rounded-lg text-sm ${
                place === activeMarketplace
                  ? "bg-blue-600 text-white border-blue-600"
                  : "hover:bg-blue-600 hover:text-white border-blue-600"
              }`}
            >
              {place}
            </p>
          ))}
        </div>
      </div>

      {/* Product Grid */}
      <div className="grid grid-cols-4 items-center gap-8">
        {loading ? (
          <div className="font-semibold text-2xl text-center w-full mt-10 capitalize">
            Loading...
          </div>
        ) : products && products.length > 0 ? (
          products.map((product, i) => (
            <div
              key={i}
              className="bg-white shadow-lg rounded-lg overflow-hidden"
            >
              <img
                src={
                  product?.image ||
                  "https://via.placeholder.com/150?text=No+Image"
                }
                alt={product.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h2 className="text-gray-800 text-lg font-semibold">
                  {product.name}
                </h2>
                <p className="text-gray-600 text-sm mt-2">
                  {product.description}
                </p>
                <div className="flex items-center justify-between mt-3">
                  <span className="text-gray-800 font-semibold text-lg">
                    ${product.price}
                  </span>
                  <button
                    onClick={() => {
                      setSelectedProductId(product._id);
                      setOpen(true);
                    }}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200"
                  >
                    Order
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-lg font-semibold text-center mt-5 capitalize w-full">
            No products found
          </div>
        )}
      </div>

      {/* Order Confirmation Modal */}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please Confirm
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to create an order for this product?
          </Typography>
          <div className="flex items-center justify-end gap-4 mt-4">
            <button
              className="text-red-500 capitalize"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
            <button
              className="text-blue-500 capitalize"
              onClick={() => {
                setOpen(false);
                if (selectedProductId) createOrder(selectedProductId);
              }}
            >
              Confirm
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default AgentProducts;
