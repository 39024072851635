/** @format */

import { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { URI } from "../URL";
import { toast } from "react-toastify";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [pendingProducts, setPendingProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [commissionDetails, setCommissionDetails] = useState({});
  const [token, setToken] = useState(localStorage.getItem("token"));

  const navigate = useNavigate();

  const getCommission = async (user, agentId) => {
    try {
      // Define the API URL
      const url = `${URI}/api/${user}/comission/${agentId}`;

      // Make the API request
      const response = await axios.get(url);

      // Check if the response is successful
      if (response.data.success) {
        const { paidCommission, unpaidCommission, orders } = response.data;

        setCommissionDetails(response.data);
        // Return the commission data
        return {
          success: true,
          paidCommission,
          unpaidCommission,
          orders,
        };
      } else {
        return {
          success: false,
          message: "Failed to fetch the agent commission data.",
        };
      }
    } catch (error) {
      console.error("Error fetching agent commission:", error);

      // Handle any error that occurs during the request
      return {
        success: false,
        message: "An error occurred while fetching the agent commission data.",
      };
    }
  };

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        `${URI}/api/login`,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      localStorage.setItem("token", data.token);
      setUser({
        ...user,
        ...data.user,
        id: data.user._id,
        name: `${data.user?.firstName} ${data.user?.lastName}`,
      });
      if (data.user.role === "admin") {
        navigate("/admin/dashboard");
      } else if (data.user.role === "manager") {
        navigate("/admin/dashboard");
        await getCommission("manager", data.user._id);
      } else if (data.user.role === "agent") {
        navigate("/agent/dashboard");
        await getCommission("agent", data.user._id);
      }
      toast.done("loged in sucessfully");
      
    } catch (err) {
      console.error(
        "Login failed",
        err.response ? err.response.data : err.message
      );
      toast.error(err.response ? err.response.data.message : err.message);
    }
  };
  const Register = async (form) => {
    try {
      const response = await axios.post(
        `${URI}/api/register`,
        { ...form },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;

      setUser({ ...user, ...data.user, id: data.user._id });

      toast.success(
        "your account request is submitted successfully. wait for your request approval"
      );
      localStorage.setItem("token", data.token);
    } catch (err) {
      console.error(
        "Login failed",
        err.response ? err.response.data : err.message
      );
      console.error(err);
      toast.error(err.response ? err.response.data.message : err.message);
    }
  };
  const getProducts = async () => {
    try {
      const token = localStorage.getItem("token");
      console.log(user);
      if (!token && !user?.role) {
        throw new Error("Authorization token is missing");
      }
      console.log("there is comes", token);

      let apiUrl = "";
      if (user.role === "admin") {
        apiUrl = `${URI}/api/products/approved`;
      } else if (user.role === "manager") {
        apiUrl = `${URI}/api/manager/products`;
      } else if (user.role === "agent"){
        apiUrl = `${URI}/api/products/approved`;
      }else{
        console.log("user role not found")
      }

      setLoading(true);

      const response = await axios.get(apiUrl, {
        headers: { Authorization: `${token}` }, // Prefix token for standard practices
      });
      console.log(response);
      setProducts(response.data);
    } catch (err) {
      console.log(err);
      console.error(
        "Product retrieval failed:",
        err.response ? err.response.data : err.message
      );
    } finally {
      setLoading(false); // Ensure loading state is updated regardless of success/failure
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
    navigate("/");
  };
  const getUser = async () => {};

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get(`${URI}/api/user`, { headers: { Authorization: token } })
        .then(({ data }) => {
          if (data.user.role === "admin") {
            // navigate("/admin/dashboard");
            // getCommission("agent",data.user.id )
          } else if (data.user.role === "manager") {
            getCommission("manager", data.user.id);
            // navigate("/admin/dashboard");
          } else if (data.user.role === "agent") {
            getCommission("agent", data.user.id);
            // navigate("/agent/dashboard");
          }
          setUser({
            ...user,
            role: data.user.role,
            name: data.user.name,
            id: data.user.id,
          });
        })
        .catch((err) => logout());
    } else {
      navigate("/");
    }
  }, [token]);
  useEffect(() => {
    getProducts();
  }, [user?.id]);

  const getpendingProducts = async () => {
    try {
      const token = localStorage.getItem("token");
      setLoading(true);
      const response = await axios.get(`${URI}/api/products/pending`, {
        headers: {
          Authorization: token,
        },
      });
      const data = response.data;
      setPendingProducts(data);
      setLoading(false);
    } catch (err) {
      console.error(
        "Login failed",
        err.response ? err.response.data : err.message
      );
      setLoading(false);
    }
  };
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    // Format the date and time (abbreviated month and up to minutes only)
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short", // This will show "Nov" instead of "November"
      day: "numeric",
    });

    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true, // This adds AM/PM
    });

    return `${formattedDate} ${formattedTime}`;
  }

  return (
    <AuthContext.Provider
      value={{
        formatTimestamp,
        user,
        login,
        logout,
        setUser,
        loading,
        products,
        getProducts,
        setProducts,
        getpendingProducts,
        pendingProducts,
        Register,
        commissionDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
