import { Autocomplete, Checkbox, TextField } from '@mui/material';
import React, { useState } from 'react';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function MultiSelection({ setForm, form }) {
  const [selectedCategories, setSelectedCategories] = useState(form?.reviewTypes || []);
  const handleAutocompleteChange = (event, value) => {
    setSelectedCategories(value);
    setForm({ ...form, reviewTypes: value });
  };

  const sizes = [
    { title: "text" },
    { title: "picture" },
    { title: "video" },
    { title: "rating" },
    { title: "no review" },
  ];

  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  return (
    <div>
      <label
        htmlFor=""
        className="block text-sm capitalize font-semibold my-2"
      >
        Check the category:
      </label>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        className="my-2"
        options={sizes}
        value={selectedCategories} // Controlled value
        onChange={handleAutocompleteChange} // Toggle selection on change
        disableCloseOnSelect
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option, value) => option.title === value.title} // Compare by title
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title}
            </li>
          );
        }}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField {...params} label="Categories" placeholder="Categories" />
        )}
      />
    </div>
  );
}

export default MultiSelection;
