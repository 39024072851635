/** @format */

import { GoArrowLeft } from "react-icons/go";
import { useNavigate, useParams } from "react-router";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import { FiUploadCloud } from "react-icons/fi";
import axios from "axios";
import { URI } from "../URL";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../context/firebase";
import MultiSelectDropdown from "../CompnentsT/MultiSelection";
import { AuthContext } from "../context/AuthContext";
import MarketplaceDropdown from "../CompnentsT/MarketplaceDropdown";

function UpdateProduct() {
  const { id: productId } = useParams();
  const navigate = useNavigate();
const {user}=useContext(AuthContext)
  const [imageUploading, setImageUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([
    "Electronics",
    "Fashion & Apparel",
    "Home & Kitchen",
    "Health & Beauty",
    "Sports & Outdoors",
  ]); // Initial options
  const [imagePreview,setImagePreview]=useState(false)
  const [file, setFile] = useState(null);
  const [form, setForm] = useState({
    name: "",
    description: "",
    price: 0,
    image: "",
    guidelines: [],
    marketPlace:"",
    productLink:'',
    marketerInstructions: [],
    commissionPolicies: [],
    comissionWithTextReview:null,
    comissionWithPictureReview:null,
    comissionWithVideoReview:null,
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [newOption, setNewOption] = useState("");

  const handleAddOption = () => {
    if (newOption.trim() && !options.includes(newOption)) {
      setOptions([...options, newOption]);
      setSelectedOption(newOption);
      setNewOption("");
    }
  };
  const validateForm = () => {
    if (!form.name.trim()) {
      toast.error("Product name is required!");
      return false;
    }
    if (!form.category.trim()) {
      toast.error("Category is required!");
      return false;
    }
    if (!form.price || form.price <= 0) {
      toast.error("Price must be a positive number!");
      return false;
    }
    if (!form.comission || form.comission < 0) {
      toast.error("Commission without review is required and cannot be negative!");
      return false;
    }

    if (!form.image && !file) {
      toast.error("Please select a thumbnail image!");
      return false;
    }

    return true;
  };
  const [newGuideline, setNewGuideline] = useState(""); // For adding a new guideline
  const [newMarketerInstruction, setNewMarketerInstruction] = useState(""); // For adding a new marketer instruction
  const [newCommissionPolicy, setNewCommissionPolicy] = useState(""); // For adding a new commission policy
  const thumbnailImg = useRef();
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImagePreview(URL.createObjectURL(selectedFile));
    }
  };
  const getSingleProduct = async (id) => {
    const token = localStorage.getItem("token");
    try {
      setLoading(true);
      const response = await axios.get(`${URI}/api/products/${id}/singleproduct`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (response.status === 200) {
        setForm(response.data.product);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleProduct(productId);
  }, [productId]);

  // useEffect(() => {
  //   const updateImage = async () => {
  //     try {
  //       setImageUploading(true);
  
  //       setImageUploading(false);
  //     } catch (error) {
  //       console.error("Image upload failed:", error);
  //       setImageUploading(false);
  //     }
  //   };
  //   if (file) {
  //     updateImage();
  //   }
  // }, [file]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleArrayChange = (field, values) => {
    setForm({
      ...form,
      [field]: values,
    });
  };

  const handleItemEdit = (field, index, value) => {
    const newItems = [...form[field]];
    newItems[index] = value;
    setForm({ ...form, [field]: newItems });
  };
  const handleItemDelete = (field, index) => {
    const newItems = form[field].filter((_, i) => i !== index);
    setForm({ ...form, [field]: newItems });
  };

  const addNewItem = (field, newItem) => {
    if (newItem.trim() !== "") {
      setForm({
        ...form,
        [field]: [...form[field], newItem],
      });
    }
    setNewCommissionPolicy('')
    setNewGuideline('')
    setNewMarketerInstruction('')
  };

  const updateProduct = async (id, form) => {
    const token = localStorage.getItem("token");
    if(!validateForm())return;
    setImageUploading(true)
    
    let downloadUrl =''
    if(file){

      const mainref = ref(storage, `images/${file.name + Date.now()}`);
      const uploadTask = await uploadBytes(mainref, file);
       downloadUrl = await getDownloadURL(uploadTask.ref);
    }
    // s÷etForm({ ...form, image: downloadUrl });
    try {
      const response = await axios.put(
        `${URI}/api/products/${id}/update`,
        {...form,image:downloadUrl||form.image},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Updated successfully", {
          autoClose: 1000,
        });
        setImageUploading(false)
        navigate(-1); // Go back to the previous page
      }
      setImageUploading(false)
    } catch (error) {
      console.error(error);
      setImageUploading(false)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateProduct(productId, form);
  };

  return (
    <>
      {loading ? (
        <div className="font-semibold bg-white text-2xl text-center w-full mt-10 capitalize">
          Loading...
        </div>
      ) : (
        <div className="px-6 bg-slate-100">
          <div className="flex items-center justify-between w-full">
            <h2 className="capitalize font-semibold text-xl">Update Product</h2>
            <button
              className="bg-black uppercase rounded-sm text-white flex gap-1 py-2 px-4 items-center justify-center"
              onClick={() => navigate(-1)}
            >
              <GoArrowLeft />
              <span className="font-semibold text-sm">Back</span>
            </button>
          </div>

          <form action="" className="w-1/2 mx-auto bg-white p-4 my-8" onSubmit={handleSubmit}>
            <h1 className="text-xl font-semibold border-b py-4 capitalize">
              Product Information
            </h1>
            <label className="block text-sm capitalize font-semibold my-2">Product Name*</label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={form.name}
              className="block p-3 placeholder:capitalize border rounded-md bg-slate-50 w-full"
              placeholder="Product name"
              required
            />

<label className="block text-sm capitalize font-semibold my-2">
          Product id*
        </label>
        <input
          type="text"
          name="productId"
          value={form.productId}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Product id"
        />

            <label className="block text-sm capitalize font-semibold my-2">Description</label>
            <textarea
              name="description"
              value={form.description}
              placeholder="Product description"
              rows={4}
              onChange={handleChange}
              className="p-2 border rounded-md bg-slate-50 block w-full"
              required
            ></textarea>
              <label className="block text-sm capitalize font-semibold my-2">category*</label>
            <input
              type="text"
              name="category"
              onChange={handleChange}
              value={form.category}
              className="block p-3 placeholder:capitalize border rounded-md bg-slate-50 w-full"
              placeholder="Category"
              required
            />
            <MarketplaceDropdown form={form} handleChange={handleChange}/>
       
        {
          user?.role==="admin"&&
        <>
        
<label className="block text-sm capitalize font-semibold my-2">
          affiliate link *
        </label>
        <input
          type="text"
          name="productLink"
          value={form.productLink}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="affiliate link"
          />
        </>

        }
        <div className="mx-auto mt-10">
  <label
    htmlFor="productCategory"
    className="block mb-2 text-gray-700 text-sm font-medium capitalize"
  >
    Product Category
  </label>
  <select
    name="productCategory"
    id="productCategory"
    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
    value={form.productCategory || ""} // Bind the value to the form state
    onChange={handleChange} // Use the handleChange function
  >
    <option value="" disabled>
      {form?.productCategory || '-- Select an option --'}
    </option>
    {options.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ))}
  </select>

  <div className="flex items-center mt-4">
    <input
      type="text"
      className="flex-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
      placeholder="Add new option"
      value={newOption}
      onChange={(e) => setNewOption(e.target.value)}
    />
    <button
      onClick={handleAddOption}
      className="ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
    >
      Add
    </button>
  </div>
</div>
<MultiSelectDropdown form={form} setForm={setForm}/>

            <label className="block text-sm capitalize font-semibold my-2">Price*</label>
            <input
              type="number"
              name="price"
              onChange={handleChange}
              value={form.price}
              className="block p-3 border rounded-md bg-slate-50 w-full"
              placeholder="Price"
              required
            />



<label className="block text-sm capitalize font-semibold my-2">
          Commission*
        </label>
        <input
          type="number"
          name="comission"
          value={form.comission}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Commission"
        />

        {/* <label className="block text-sm capitalize font-semibold my-2">
          Commission with Text Review*
        </label>
        <input
          type="number"
          name="comissionWithTextReview"
          value={form.comissionWithTextReview}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Commission with Text Review"
        />
      <label className="block text-sm capitalize font-semibold my-2">
          Commission with Image Review*
        </label>
        <input
          type="number"
          name="comissionWithPictureReview"
          value={form.comissionWithPictureReview}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Commission with Text Review"
        />
        <label className="block text-sm capitalize font-semibold my-2">
          Commission with video Review*
        </label>
        <input
          type="number"
          name="comissionWithVideoReview"
          value={form.comissionWithVideoReview}
          onChange={handleChange}
          className="block p-3 placeholder:capitalize border rounded-md focus:border-blue-200 outline-none bg-slate-50 w-full"
          placeholder="Commission with Text Review"
        /> */}
            {/* Review Guidelines */}
            <label className="block text-sm capitalize font-semibold my-2">Review Guidelines</label>
            <div className="flex items-center gap-2">
                <input
                  type="text"
                  value={newGuideline}
                  onChange={(e) => setNewGuideline(e.target.value)}
                  placeholder="Add new guideline"
                  className="block p-3 border rounded-md bg-slate-50 w-full mb-4"
                />
                <button
                  type="button"
                  onClick={() => addNewItem("guidelines", newGuideline)}
                  className="bg-blue-500 text-white p-2 rounded-md"
                >
                  Add
                </button>
              </div>
            <div>
              {form.guidelines.map((item, index) => (
                <div key={index} className="flex items-center gap-2 mb-2">
                  <input
                    type="text"
                    value={item}
                    onChange={(e) => handleItemEdit("guidelines", index, e.target.value)}
                    className="p-2 border rounded-md bg-slate-50 w-full"
                  />
                  <button
                    type="button"
                    className="text-red-500"
                    onClick={() => handleItemDelete("guidelines", index)}
                  >
                    Delete
                  </button>
                </div>
              ))}
          
            </div>

            {/* Marketer Instructions */}
            <label className="block text-sm capitalize font-semibold my-2">Marketer Instructions</label>
            <div className="flex items-center gap-2">
                <input
                  type="text"
                  value={newMarketerInstruction}
                  onChange={(e) => setNewMarketerInstruction(e.target.value)}
                  placeholder="Add new marketer instruction"
                  className="block p-3 border rounded-md bg-slate-50 w-full mb-4"
                />
                <button
                  type="button"
                  onClick={() => addNewItem("marketerInstructions", newMarketerInstruction)}
                  className="bg-blue-500 text-white p-2 rounded-md"
                >
                  Add
                </button>
              </div>
            <div>
              {form.marketerInstructions.map((item, index) => (
                <div key={index} className="flex items-center gap-2 mb-2">
                  <input
                    type="text"
                    value={item}
                    onChange={(e) => handleItemEdit("marketerInstructions", index, e.target.value)}
                    className="w-full p-2 border rounded-md bg-slate-50"
                  />
                  <button
                    type="button"
                    className="text-red-500"
                    onClick={() => handleItemDelete("marketerInstructions", index)}
                  >
                    Delete
                  </button>
                </div>
              ))}
             
            </div>

            {/* Commission Policies */}
            <label className="block text-sm capitalize font-semibold my-2">Commission Policies</label>
            <div className="flex items-center gap-2 mb-4">
                <input
                  type="text"
                  value={newCommissionPolicy}
                  onChange={(e) => setNewCommissionPolicy(e.target.value)}
                  placeholder="Add new commission policy"
                  className="block p-3 border rounded-md bg-slate-50 w-full mb-4"
                />
                <button
                  type="button"
                  onClick={() => addNewItem("commissionPolicies", newCommissionPolicy)}
                  className="bg-blue-500 text-white p-2 rounded-md"
                >
                  Add
                </button>
              </div>
            <div>
              {form.commissionPolicies.map((item, index) => (
                <div key={index} className="flex items-center gap-2 mb-2">
                  <input
                    type="text"
                    value={item}
                    onChange={(e) => handleItemEdit("commissionPolicies", index, e.target.value)}
                    className="p-2 border rounded-md bg-slate-50 w-full"
                  />
                  <button
                    type="button"
                    className="text-red-500"
                    onClick={() => handleItemDelete("commissionPolicies", index)}
                  >
                    Delete
                  </button>
                </div>
              ))}
           
            </div>


            <label className="block text-sm capitalize font-semibold my-2">Thumbnail Image*</label>
            <input
              type="file"
              ref={thumbnailImg}
              hidden
              onChange={handleFileChange}
            />
            <label
              className="flex items-center border w-max p-3 gap-3 hover:bg-blue-200 cursor-pointer"
              onClick={() => thumbnailImg.current.click()}
            >
              <FiUploadCloud />
              <span>Select Image</span>
            </label>
            {imagePreview? <div className="relative h-52 object-cover w-auto">
                <img src={imagePreview} alt="Product thumbnail" className="w-full h-full" />
                <button
                  className="absolute top-0 right-0 bg-white text-red-400 p-1 rounded-full"
                  onClick={(e) => {
                    e.preventDefault();
                    setForm({ ...form, image: "" });
                    setImagePreview(null)
                  }}
                >
                  <RxCross1 />
                </button>
              </div>:form.image&& <div className="relative h-52 object-cover w-auto">
                <img src={form.image} alt="Product thumbnail" className="w-full h-full" />
                <button
                  className="absolute top-0 right-0 bg-white text-red-400 p-1 rounded-full"
                  onClick={(e) => {
                    e.preventDefault();
                    setForm({ ...form, image: "" });
                    setImagePreview(null)
                  }}
                >
                  <RxCross1 />
                </button>
              </div> }


            <button
            disabled={imageUploading}
              className="text-white bg-black py-3 mt-6 text-center w-full"
              type="submit"
            >
              {
                imageUploading?"updating...":'Update Product'
              }
              
            </button>
          </form>
        </div>
      )}
    </>
  );
}

export default UpdateProduct;
